import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isLargeScreen } from '../../../../../global.variable';
import { AddEditClientComponent } from '../../../../shared/components/add-edit-client/add-edit-client.component';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { DialogRef } from '../../../../shared/components/modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../../../../shared/components/save-new-address/save-new-address.component';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ProfileService } from '../../../profile/profile.service';
import { ClientsService } from '../../clients/clients.service';
import { JobsService } from '../../jobs/jobs.service';
import { UserPromptService } from '../../user-prompts/user-prompt.service';
import { CheckpointService } from '../checkpoint.service';
import { DeviceInfoService } from '../../../../shared/services/device-info.service';
@Component({
  selector: 'gtapp-add-edit-checkpoint',
  templateUrl: './add-edit-checkpoint.component.html',
  styleUrl: './add-edit-checkpoint.component.scss',
})
export class AddEditCheckpointComponent implements OnInit {
  @Input()
  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    company: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    site: new UntypedFormControl(''),
    site_id: new UntypedFormControl(''),
    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    description: new UntypedFormControl(),
    address1: new UntypedFormControl(),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
  });

  addressValue: any;

  showMap: any;
  latLon: any;
  largeView: Boolean = isLargeScreen;
  distanceFromLocation: any;

  clientListData: any = [];
  siteListData: any = [];

  addressLookupData: any;

  minKm: number = 5;
  maxKm: number = 1000;
  nearestKmValue = 100;
  currentStep: number = 1;

  // prompt variables
  selectedUserPrompts: any = [];
  availableUserPrompts: any = [];

  userPromptsRows: number = 5;
  userPromptsPrevious: number = 0;
  userPromptsPageNum: number = 1;
  userPromptsTotalPages: number = 0;
  userPromptsTotalCount: number = 0;
  userPromptsSearchResults: any = [];

  searchClientValue: string = '';
  searchSiteValue: string = '';

  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private profileService: ProfileService,
    private jobService: JobsService,

    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() protected dialogref: DialogRef,
    private userPromptService: UserPromptService,
    private toasterService: ToasterService,
    private customerService: ClientsService,
    private deviceInfoService: DeviceInfoService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    this.showMap = false;
  }

  ngOnInit() {
    this.currentStep = 1;
    this.shiftFocus('addressSearch');
    this.getUserPrompts();

    let fromSiteInfo = JSON.parse(sessionStorage.getItem('fromSite') || '{}');
    if (Object.keys(fromSiteInfo).length) {
      this.checkPointForm.controls['site_id'].setValue(fromSiteInfo?.site?.id);
      this.checkPointForm.controls['site'].setValue(
        fromSiteInfo?.site?.company_name
      );

      this.checkPointForm.controls['company_id'].setValue(
        fromSiteInfo?.client?.id
      );
      this.checkPointForm.controls['company'].setValue(
        fromSiteInfo?.client?.company_name
      );
      sessionStorage.removeItem('fromSite');
    }
    let fromClientInfo = JSON.parse(
      sessionStorage.getItem('fromClient') || '{}'
    );
    if (Object.keys(fromClientInfo).length) {
      this.checkPointForm.controls['company_id'].setValue(
        fromClientInfo?.client?.id
      );
      this.checkPointForm.controls['company'].setValue(
        fromClientInfo?.client?.company_name
      );
      sessionStorage.removeItem('fromClient');
    }
  }

  onClientSelect(data: any) {
    if (data?.key === 0) {
      this.openAddCustomerForm();
      this.checkPointForm.controls['company_id'].setValue(null);
      this.checkPointForm.controls['company'].setValue(null);
    }
    if (data?.id) {
      this.checkPointForm.controls['company_id'].setValue(data.id);
      this.checkPointForm.controls['company'].setValue(data.company_name);
      this.checkPointForm.controls['site_id'].setValue(null);
      this.checkPointForm.controls['site'].setValue(null);
      // this.shiftFocus('description');
    }
  }
  onSiteSelect(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
      this.checkPointForm.controls['site_id'].setValue(null);
      this.checkPointForm.controls['site'].setValue(null);
    }
    if (data?.id) {
      this.checkPointForm.controls['site_id'].setValue(data.id);
      this.checkPointForm.controls['site'].setValue(data.company_name);

      if (data?.company) {
        this.checkPointForm.controls['company_id'].setValue(data?.company?.id);
        this.checkPointForm.controls['company'].setValue(
          data?.company?.company_name
        );
      }

      // this.shiftFocus('description');
    }
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        showFrom: 'addJob',
        siteAddress: this.searchSiteValue,
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data !== false) {
        this.onSiteSelect(data);
      }
    });
  }

  onAddressSearch(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.addressLookupData?.length)
    ) {
      this.profileService
        .addressLookup(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.addressLookupData = res?.data;
          }
        });
    }
  }

  shiftFocus(elementId: string, time: number = 100) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      if (element) {
        element?.focus();
      }
    }, time);
  }

  addressSelected(address: any) {
    if (address?.key === 0) {
      this.addPlace({ latLon: undefined, showFrom: 'cpPage' });
    } else if (address && typeof address === 'object') {
      this.addressValue = address?.address1 + ' ' + address?.address2;
      // this.checkPointForm.controls['name'].setValue(
      //   address?.address1 + ' ' + address?.address2
      // );
      // this.checkPointForm.controls['name'].updateValueAndValidity();

      this.latLon = { lat: 0, lon: 0 };
      this.latLon.lat = address?.latitude;
      this.latLon.lon = address?.longitude;

      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode),
        this.checkPointForm.controls['state_code'].setValue(
          address?.state_code
        ),
        this.checkPointForm.controls['country_name'].setValue(
          address?.country_name
        ),
        this.checkPointForm.controls['latitude'].setValue(address?.latitude),
        this.checkPointForm.controls['longitude'].setValue(address?.longitude),
        (this.showMap = true);

      var element = <HTMLInputElement>document.getElementById('addressSearch');
      if (element) {
        element.disabled = false;
        element.blur();
      }

      this.shiftFocus('cpName');
    }
  }
  onClearForm() {
    this.checkPointForm.reset();
  }
  onCloseDialogue() {
    this.dialogref.close(false);
  }
  emitData(event: any) {
    this.checkPointForm.controls['latitude'].setValue(event.lat);
    this.checkPointForm.controls['longitude'].setValue(event.lng);
  }
  addCheckpointCount() {
    let subscriberStats = JSON.parse(
      localStorage.getItem('subscriberStats') || '{}'
    );
    subscriberStats.checkpoint_count =
      (subscriberStats?.checkpoint_count || 0) + 1;
    localStorage.setItem('subscriberStats', JSON.stringify(subscriberStats));
  }
  updateMinDistance(event: any) {
    this.checkPointForm.controls['min_distance'].setValue(event);
  }

  onClickSubmit() {
    let requestData = this.checkPointForm.value;

    if (requestData.site_id == null || requestData.site_id == undefined) {
      delete requestData.site_id;
    }

    if (this.checkPointForm.valid) {
      this.spinnerService.show();
      this.checkpointService
        .addCheckPoint(requestData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.addCheckpointCount();

            this.checkPointForm.reset();

            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this._location.back();
          } else {
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
          this.spinnerService.hide();
        });
    }
  }

  addPlace(context = {}) {
    this.showMap = false;
    this.dialogref = this.dialogService.open(SaveNewAddressComponent, {
      data: context,
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);

          this.showMap = true;

          // this.stepper.next();
          this.shiftFocus('cpName', 1000);
        }
      }
    });
  }
  onClientSearch(event: any) {
    this.searchClientValue = event.target.value;
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.clientListData?.length)
    ) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.clientListData = res?.data;
          }
        });
    }
  }
  onSiteSearch(event: any) {
    this.searchSiteValue = event.target.value;
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.siteListData?.length)
    ) {
      this.customerService
        .getSites({
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteListData = res?.data;
          }
        });
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddEditClientComponent, {
      data: { customerName: this.searchClientValue },
    });

    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
      } else {
        this.searchClientValue = '';
      }
    });
  }

  async useCurrentLocation() {
    this.spinnerService.show();
    this.latLon = await this.deviceInfoService.getGpsCoordinates();

    this.spinnerService.hide();
    this.addPlace({ latLon: this.latLon, showFrom: 'cpPage' });
  }
  goBack() {
    this.showMap = false;
    this.spinnerService.show();
    setTimeout(() => {
      this.showMap = true;
      this.spinnerService.hide();
    }, 200);
  }
  searchPrompts(event: any) {
    if (event.target.value?.length > 2) {
      const params: any = { search_str: event.target.value };
      this.userPromptService
        .getUserPromptList(params)
        .subscribe((response: any) => {
          this.userPromptsSearchResults = response?.data?.filter(
            (value1: any) =>
              !this.selectedUserPrompts.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        });
    }
  }

  onLoadMore() {
    this.userPromptsPrevious = this.userPromptsPrevious + this.userPromptsRows;

    this.getUserPrompts();
  }
  getUserPrompts(event?: any) {
    let params: any = {};
    if (this.userPromptsRows) {
      params['rows'] = this.userPromptsRows;
    }
    if (this.userPromptsPrevious) {
      params['previous'] = this.userPromptsPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }

    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          response?.data.forEach((item1: any) => {
            if (
              !this.availableUserPrompts.some(
                (item2: any) => item1?.id === item2?.id
              )
            ) {
              this.availableUserPrompts.push(item1);
            }
          });
          this.makeSelectionChanges();
          this.userPromptsTotalCount = response['total_size'];
          this.userPromptsTotalPages = Math.ceil(
            this.userPromptsTotalCount / this.userPromptsRows
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  removeSelectedUserPrompt(deleteUserPrompt: any) {
    this.selectedUserPrompts = this.selectedUserPrompts?.filter(
      (savedPrompt: any) => savedPrompt?.id !== deleteUserPrompt?.id
    );

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }
  addSelectedUserPrompt(userPrompt: any) {
    if (
      this.selectedUserPrompts?.some(
        (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
      )
    ) {
      this.toasterService.setMessage({
        errorMessage: 'User Prompt Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedUserPrompts.push(userPrompt);
      if (
        !this.availableUserPrompts?.some(
          (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(userPrompt);
      }
    }

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }
  addRemoveUserPrompt(userPrompt: any) {
    userPrompt.selected = !userPrompt?.selected;
    if (userPrompt.selected) {
      this.addSelectedUserPrompt(userPrompt);
    } else {
      this.removeSelectedUserPrompt(userPrompt);
    }
  }
  makeSelectionChanges() {
    this.availableUserPrompts.forEach((uPrompt: any) => {
      uPrompt.selected = this.selectedUserPrompts?.some(
        (selectedPrompt: any) => selectedPrompt?.id === uPrompt?.id
      );
    });
    this.updateUserPromtOrder();
  }
  dropUserPrompt(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.availableUserPrompts,
      event.previousIndex,
      event.currentIndex
    );
  }
  updateUserPromtOrder() {
    this.availableUserPrompts.sort((a: any, b: any) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
  }
  editAddress() {
    this.checkPointForm.patchValue({
      address1: '',
      address2: '',
      address3: '',
    });
  }
}
