<div class="card dialog-box">
  <div class="card-header">
    <span>
      {{
        editUser
          ? detailData?.full_name
            ? detailData?.full_name
            : "User Info"
          : "Add User"
      }}
    </span>
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div>
    <!-- Add User Form -->
    <ng-container *ngIf="!editUser">
      <form [formGroup]="userForm" #f="ngForm">
        <div class="row gy-3 gx-0">
          <div class="form-floating">
            <input
              type="email"
              class="form-control"
              id="email"
              formControlName="email"
              autocomplete="off"
              placeholder="Enter email"
              autofocus
              autocapitalize="off"
            />
            <label for="email">Email</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="first_name"
              formControlName="first_name"
              autocomplete="off"
              placeholder="First Name"
              autofocus
            />
            <label>First Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="last_name"
              formControlName="last_name"
              autocomplete="off"
              placeholder="Last Name"
              autofocus
            />
            <label>Last Name</label>
          </div>
        </div>

        <div class="form-group mt-2">
          <label for="group_ids"> <dt>User Groups</dt></label>
          <div class="row row-cols-auto">
            <div *ngFor="let grp of userGroupListData">
              <label for="{{ grp?.id }}">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="grp?.selected"
                  id="{{ grp?.id }}"
                  (change)="grp.selected = !grp?.selected"
                />
                {{ grp.name }}</label
              >
            </div>
          </div>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="onClearForm()"
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              [disabled]="!userForm.valid"
              class="btn btn-lg btn-primary w-100"
              (click)="sendAddUserLink()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- User Details -->
    <ng-container *ngIf="editUser">
      <div class="alert alert-primary text-center">
        {{ detailData?.user_app_status | titlecase }}
        <div *ngIf="detailData?.last_activity_time" class="fw-bold">
          Last activity
          {{ detailData?.last_activity_time | customDate : "d MMM y HH:mm" }}
        </div>
      </div>

      <div class="text-center">
        <img
          *ngIf="detailData?.profile_image"
          src="{{ detailData?.profile_image }}"
          alt="User Profile Image"
          class="img-fluid rounded-circle"
          style="height: 120px"
        />
        <img
          *ngIf="!detailData?.profile_image"
          src="assets/images/img_avatar_blank.avif"
          alt="User Profile Image"
          class="img-fluid rounded-circle"
          style="border: 2px solid black; height: 120px"
        />
      </div>

      <div class="my-3">
        <dt>Name</dt>
        <dd>
          @if(userFullName ){
          {{ userFullName }}
          }@else {[USER NOT SIGNED-IN YET]}
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:{{ detailData?.email }}">{{ detailData?.email }}</a>
        </dd>

        <ng-container *ngIf="detailData?.mobile_number">
          <dt>Mobile</dt>
          <dd>
            <a href="tel:+{{ detailData?.mobile_number }}">{{
              detailData?.mobile_number
            }}</a>
          </dd>
        </ng-container>

        <ng-container *ngIf="isAdmin && fromUserPage">
          <div class="form-group">
            <label for="group_ids"> <dt>User Groups</dt></label>
            <div class="row row-cols-auto">
              <div *ngFor="let grp of userGroupListData">
                <label for="{{ grp?.id }}">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="grp?.selected"
                    [disabled]="grp?.disabled"
                    id="{{ grp?.id }}"
                    (change)="
                      addRemoveGroup(grp?.id, grp?.selected ? 'remove' : 'add')
                    "
                  />
                  {{ grp.name }}</label
                >
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isAdmin && !fromUserPage">
          <dt>User Groups</dt>
          <span *ngFor="let grp of detailData?.user_group; let l = last">
            <span>{{ grp?.name }} <span *ngIf="!l">, </span></span>
          </span>
        </ng-container>
      </div>
      <ng-container
        *ngIf="!detailData?.is_registered && isAdmin && fromUserPage"
      >
        <div class="col">
          <button
            class="btn btn-primary btn-lg"
            (click)="reInviteUser(detailData)"
          >
            Resend User Sign In Link
          </button>
        </div>
      </ng-container>
      <div class="row row-cols-2 mt-4" *ngIf="!isOwner && fromUserPage">
        <div class="col">
          <button class="btn btn-lg btn-warning w-100">
            {{ detailData?.is_active ? "Disable Sign-In" : "Enable Sign-In" }}
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-lg btn-danger w-100"
            (click)="deleteUserById(detailData)"
          >
            Delete User
          </button>
        </div>
      </div>

      <div class="text-center mt-3" *ngIf="!detailData?.license?.length">
        <div class="alert alert-warning">
          This user has not saved any Security Licences on their user profile
        </div>
      </div>

      <!-- List of User Licenses -->
      <div *ngIf="detailData.license?.length">
        <hr />
        <div class="fw-bold">Licenses</div>
        <gtapp-card-list
          [mobileCardBackendData]="detailData?.license"
          [mobileCardDetails]="licenseDetails"
        ></gtapp-card-list>
      </div>
    </ng-container>
  </div>
</div>
