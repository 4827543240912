<div class="mb-2 fw-bold">
  Add Job
  <span class="cursor-pointer">
    {{ selectedStatus?.name ? " > " + selectedStatus?.name : "" }}</span
  >
</div>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <!-- Form begins -->
    <form [formGroup]="responseFormData">
      <!-- Site name input -->
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 my-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              [routerLink]="['/jobs']"
              [queryParams]="{ cancel: true }"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!responseFormData.value?.siteName"
              (click)="currentStep = 2"
            >
              Next
            </button>
          </div>
        </div>
        <!-- Client name display -->
        <div *ngIf="responseJobType.client.data" class="fw-bold mb-2">
          <dl>
            <dt>Client</dt>
            <dd>
              {{ responseJobType.client.value }}
            </dd>
          </dl>
        </div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteList"
            placeHolder="Search Sites"
            searchKeyword="company_name"
            [initialValue]="responseFormData.value.siteName"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="isAdmin"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSelectSite($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div>{{ item?.company_name }}</div>
            <div class="small-font fw-bold">
              {{ item?.company?.company_name }}
            </div>
            <div class="small-font">{{ item?.address?.full_address }}</div>
          </div>
          }
        </ng-template>
        <div
          *ngIf="
            showSiteMap &&
            responseJobType.site?.data?.address?.latitude &&
            responseJobType.site?.data?.address?.longitude &&
            !largeView
          "
        >
          <gtapp-map-leaflet
            [latLon]="{
              lat: responseJobType.site?.data?.address?.latitude,
              lon: responseJobType.site?.data?.address?.longitude
            }"
            [mapId]="'largeJob'"
          ></gtapp-map-leaflet>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <ng-container *ngIf="responseJobType.site.data">
          <!-- Assign Guard -->
          <div class="row row-cols-2 my-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-dark w-100"
                (click)="currentStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                [disabled]="!selectedStatus.enable_assignees"
                (click)="currentStep = 3"
              >
                Next
              </button>
            </div>
          </div>

          <div *ngIf="selectedStatus.enable_assignees === true">
            <div *ngIf="selectedStatus.enable_assignees === true" class="mb-3">
              <gtapp-auto-complete
                [data]="assigneeList"
                placeHolder="Search Guards"
                searchKeyword="full_name"
                [initialValue]="responseFormData.value.assigneeName"
                [itemTemplate]="assigneeTemplate"
                (inputChanged)="onAssigneeSearch($event)"
                (selected)="onAssigneeSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #assigneeTemplate let-item>
              <div>
                {{ item?.full_name }}
                <small *ngIf="!item?.validStateLicense">
                  <i class="fa-solid fa-shield-halved"></i>
                </small>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!selectedStatus.enable_assignees"
              (click)="currentStep = 4"
            >
              Next
            </button>
          </div>
        </div>

        <!-- Response Type -->
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="responseTypeList"
            placeHolder="Response Type"
            searchKeyword="name"
            [initialValue]="responseFormData.value.responseTypeName"
            [itemTemplate]="responseTypeTemplate"
            [addInitialKey]="isAdmin"
            (inputChanged)="onRtSearch($event)"
            (selected)="onResponseTypeSelect($event)"
          >
          </gtapp-auto-complete>
          <ng-template #responseTypeTemplate let-item>
            @if (item?.key === 0) {
            <div>Add Response Type</div>
            } @else {
            <div>
              {{ item.name }}
            </div>
            }
          </ng-template>
        </div>
        <!-- Monitoring Company -->
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="monitoringCompanyList"
            placeHolder="Monitoring Company"
            searchKeyword="company_name"
            [initialValue]="responseFormData.value.monitoringCompanyName"
            [itemTemplate]="MCTemplate"
            [addInitialKey]="isAdmin"
            (inputChanged)="onMcSearch($event)"
            (selected)="onMonitoringSelect($event)"
          >
          </gtapp-auto-complete>
        </div>
        <ng-template #MCTemplate let-item>
          @if (item?.key === 0) {
          <div>Add Monitoring Company</div>
          } @else {
          <div>
            {{ item.company_name }}
          </div>
          }
        </ng-template>
      </ng-container>
      <ng-container *ngIf="currentStep === 4">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 3"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!selectedStatus.enable_assignees"
              (click)="currentStep = 5"
            >
              Next
            </button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <textarea
              class="form-control"
              placeholder="Additional Information"
              cols="20"
              rows="10"
              wrap="hard"
              maxlength="900"
              style="width: 100%"
              type="text"
              [(ngModel)]="responseJobType.ai.value"
              formControlName="requestDetail"
            ></textarea>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 5">
        <!-- Buttons for final actions -->
        <div class="row row-cols-2 mb-2">
          <div class="col">
            <button
              class="btn btn-dark btn-lg w-100"
              type="button"
              (click)="currentStep = 4"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              [disabled]="!responseJobType.site.data || addJobPressed"
              (click)="onAddJob()"
            >
              Add Job
            </button>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input
            class="form-control"
            id="mobeIField"
            nbInput
            type="text"
            placeholder="Ext. Job ID"
            formControlName="externalJobId"
            autocomplete="off"
          />
          <label>Ext. Job ID</label>
        </div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteContacts"
            placeHolder="Onsite Contact (Optional)"
            searchKeyword="full_name"
            [initialValue]="responseFormData.value.siteContactName"
            [itemTemplate]="siteContactSearch"
            [addInitialKey]="true"
            (selected)="selectContact($event)"
          >
          </gtapp-auto-complete>
        </div>
        <ng-template #siteContactSearch let-item>
          @if (item?.key === 0) {
          <div *ngIf="isAdmin && responseJobType.site.data">
            Add New Contact
          </div>
          } @else {
          <div>
            {{ item?.full_name }}
          </div>
          }
        </ng-template>
      </ng-container>
    </form>
  </div>

  <div class="col">
    <div
      *ngIf="
        showSiteMap &&
        responseJobType.site?.data?.address?.latitude &&
        responseJobType.site?.data?.address?.longitude &&
        largeView
      "
    >
      <gtapp-map-leaflet
        [latLon]="{
          lat: responseJobType.site?.data?.address?.latitude,
          lon: responseJobType.site?.data?.address?.longitude
        }"
        [mapId]="'largeJob'"
      ></gtapp-map-leaflet>
    </div>
  </div>
</div>

<!-- License Reminder Check Template -->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <div class="card dialog-box">
    <div class="card-header">
      WARNING

      <span
        class="float-end"
        (click)="
          licenceCheckTemplateRef.close(); editOtherJobTypeItem('assignee')
        "
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div>
      <div class="alert alert-danger">
        You have not added any security licences for
        {{ responseJobType?.site?.data?.address?.state_code }}

        <div class="mt-2">
          Licences can be added in
          <a
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
            routerLink="/company-profile"
            class="link-primary"
          >
            Company Profile
          </a>
        </div>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="licenseCheckbox"
          (change)="licenseReminderField = !licenseReminderField"
        />
        <label class="form-check-label" for="licenseCheckbox">
          Don't show this again
        </label>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-secondary btn-lg w-100"
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
