import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, takeWhile } from 'rxjs';
import { environment } from '../../../environments/environment';
import { isLargeScreen } from '../../../global.variable';
import {
  MENU_ITEMS,
  appUserMenu,
  superUserMenu,
} from '../../../sidebar-user-menu';
import { AppService } from '../../app.service';
import { PermissionsService } from '../../auth/permissions.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { MultipleSubscribersComponent } from '../../shared/components/multiple-subscribers/multiple-subscribers.component';
import { PwaInstallationGuideComponent } from '../../shared/components/pwa-installation-guide/pwa-installation-guide.component';
import { WhatsNewComponent } from '../../shared/components/whats-new/whats-new.component';
import { DataCheckService } from '../../shared/services/data-check.service';
import { gTDB } from '../../shared/services/db';
import { DeviceInfoService } from '../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../shared/services/orientationlock.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ProfileService } from '../profile/profile.service';
import { AppChecksComponent } from './app-checks/app-checks.component';
import { JobsService } from './jobs/jobs.service';
import { PagesService } from './pages.service';
import { currentTheme } from '../../../global.variable';

declare var bootstrap: any;
declare let html2canvas: any;
@Component({
  selector: 'gtapp-pages',
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss',
})
export class PagesComponent implements OnInit {
  currentTheme: string = currentTheme;

  sideBarMenuItems: any;

  loggedInUser: any = {};

  navbarToggled: boolean = false;

  settingItems: any = [];

  isSubscriptionActive: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  isClockedIn: boolean = false;
  isGuardUser: any;
  isSubscriberAdmin: boolean = false;

  isSuperUser: boolean = false;
  userData: any;

  largeView: Boolean = isLargeScreen;
  // notification params
  notificationCount: any;
  notificationData: any = [];
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  showWelfareCheckButton: boolean = false;
  welfareTempHoldValue: boolean = false;
  userMenu: any = appUserMenu;

  @ViewChild('toggleButton')
  toggleButton!: ElementRef;
  @ViewChild('menu')
  menu!: ElementRef;
  @ViewChild('menuSside')
  menuSside!: ElementRef;

  showBottomButton = true;
  windowScrolled: boolean = false;
  showUserDropdown: boolean = false;

  hasMultipleAssociation: boolean = false;
  ipAddress: any;
  patrolData: any;

  subscriberStats: any;
  expandIcon: boolean = false;

  currentRoute: string = '';

  dialogRef: any;

  showManageMenu: boolean = false;

  isInBackgroundSync: boolean = false;
  isAppOnline: boolean = true;
  isOnEvent: boolean = false;
  offlineMode: boolean = false;
  messageChannel = new MessageChannel();
  isDragging = false;

  dragging: boolean = false;
  intervalId: any;

  deferredPrompt: any;
  isHomeComponent: boolean = false;
  allowedPaths: boolean = false;

  isOnlyGuardAndDispatcher: boolean = false;
  isPreviousSubscribed: boolean = false;

  feedbackScreenShot: any;

  feedbackForm = new FormGroup({
    feedback: new FormControl('', [
      Validators.required,
      Validators.maxLength(340),
    ]),
  });
  notificationPermission: any;
  sosAlertCount: number = 0;
  beepIntervalSubscription: any;
  isAlertActive: boolean = false;
  permissionErrorStatus: any;
  // varaible to check how many days are left for the subscriber in the grace period
  isInGracePeriod: any = this.dataCheckService.isInGracePeriod();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private profileService: ProfileService,
    private spinnerService: LoadingSpinnerService,
    private deviceService: DeviceDetectorService,
    private pageService: PagesService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private ngZone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private jobService: JobsService,
    private toasterService: ToasterService,
    private swPush: SwPush,
    private orientationLockService: OrientationLockService,
    private permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef,
    private deviceInforService: DeviceInfoService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
    this.offlineMode = !navigator?.onLine;
    this.updateAppCheck();

    this.currentRoute = '';

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar

        this.enableScrollOnBody();
        this.pageService.refreshToken().then((value: any) => {
          if (value) this.updateLoggedInUserDetail();
        });
      }

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        this.getNotificationCount();
        // Hide progress spinner,alert message or progress bar
        this.currentRoute = event.url;

        this.isHomeComponent =
          event.urlAfterRedirects === '/dashboard' ||
          event.urlAfterRedirects === '/';
        this.allowedPaths = ['/profile', '/support', '/subscription'].includes(
          event.urlAfterRedirects
        );
        this.isInGracePeriod = this.dataCheckService.isInGracePeriod();
        if (event.urlAfterRedirects === '/subscription') {
          this.isInGracePeriod = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar
        // Present error to user
        console.error(event.error);
      }
      this.isSubscriptionActive =
        this.dataCheckService.checkIsSubscriptionOrTestUser();
    });

    this.windowScrolled = false;
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService?.isSuperUser();
    this.isSubscriberAdmin = this.dataCheckService?.isSubscriberAdmin();
    this.isAdmin = this.dataCheckService?.isUserAdmin();
    this.isDispatchUser = this.dataCheckService?.isDispatchUser();
    this.isGuardUser = this.dataCheckService?.isGuardUser();
    this.isOnlyGuardAndDispatcher =
      (this.isGuardUser || this.isDispatchUser) && !this.isAdmin;
    this.isClockedIn = this.dataCheckService?.isGuardUserClockendIn();
    this.isPreviousSubscribed =
      this.dataCheckService?.checkIsPreviouslySubscribedAndSubscriptionInActive();
    this.isSubscriptionActive =
      this.dataCheckService.checkIsSubscriptionOrTestUser();
    this.hasMultipleAssociation =
      this.dataCheckService?.hasMultipleSubscriberAssociation();

    // this.currentTheme = this.userData?.preferences?.theme || 'default';
    //this.themeService.changeTheme(this.currentTheme);
  }

  updateAppCheck() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // to trigger the installation prompt
    });
  }
  openChangeLog() {
    this.spinnerService.show();
    this.profileService.getVersionInfo().subscribe((response: any) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();

        this.dialogRef = this.dialogService.open(WhatsNewComponent, {
          data: { latestVersion: response },
        });
      }
      this.spinnerService.hide();
    });
  }
  showSubscriptionOption(): boolean {
    if (this.isSubscriberAdmin) {
      if (this.dataCheckService.isTestAccount()) return false;
      if (this.dataCheckService.isTrialAccount()) return true;
      return true;
    }

    return false;
  }

  async arrangeUserMenu() {
    this.userMenu = appUserMenu;
    this.userMenu = this.userMenu.filter((item: any) => {
      if (item.title === 'Clock Out') {
        return Boolean(this.isClockedIn && this.isSubscriptionActive);
      } else if (item.title === 'Clock In') {
        return Boolean(
          this.isSubscriptionActive &&
            !this.isOnlyGuardAndDispatcher &&
            !this.isClockedIn
        );
      } else if (item.title === 'Subscription') {
        return Boolean(
          this.showSubscriptionOption() && this.checkPrevouslySubscribed()
        );
      } else if (item.title === 'Register QR Code') {
        return (this.isAdmin || this.isClockedIn) && this.isSubscriptionActive;
      } else if (item.title === 'Edit Profile') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Support') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'App Version') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Change Account') {
        return this.hasMultipleAssociation;
      } else {
        return true;
      }
    });
  }
  async getSideBar() {
    this.spinnerService.show();

    this.profileService
      .getUserConfig({
        remember_login: this.dataCheckService.isTrustedDevice() ? 1 : 0,
        time_offset: new Date().getTimezoneOffset(),
      })
      .subscribe((resp: any) => {
        if (resp['status'] == 'success') {
          let permissionToken = resp?.user_permission_token;
          let userData = this.appService.getUserData();
          if (permissionToken) {
            userData.user_token = permissionToken;
          }
          this.appService.setUserData(userData);
          this.isSubscriptionActive =
            this.dataCheckService.checkIsSubscriptionOrTestUser();
          if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
            this.router.navigate([
              '/subscription',
              {
                state: 'Account Verified! Subscription Required',
              },
            ]);
          }
          if (!this.isGuardUser || this.isClockedIn || this.isAdmin) {
            this.sideBarMenuItems = resp['data'];
            if (this.sideBarMenuItems.length === 0) {
              this.sideBarMenuItems = MENU_ITEMS;
            }

            if (this.isAdmin) {
              this.settingItems = [
                {
                  title: 'COMPANY PROFILE',
                  link: '/company-profile',
                  icon: 'pantone-outline',
                },
                {
                  title: 'GuARDS & GROUPS',
                  link: '/users',
                  icon: 'people-outline',
                },
                {
                  title: 'INCIDENT TYPES',
                  link: '/incident-type',
                  icon: 'layers-outline',
                },
                {
                  title: 'MONITORING COMPANIES',
                  link: '/monitoring-company',
                  icon: 'shield-outline',
                },
                {
                  title: 'RESPONSE TYPES',
                  link: '/response-type',
                  icon: 'layers-outline',
                },
                {
                  title: 'USER PROMPTS',
                  link: '/user-prompts',
                  icon: 'layers-outline',
                },
              ];
            }
          } else {
            this.sideBarMenuItems = [];
          }
        }
        this.spinnerService.hide();
        this.arrangeUserMenu();
      });
  }
  checkWelfareChecks() {
    if (!this.isOnEvent && this.welfareTempHoldValue) {
      this.showWelfareCheckButton = true;
    }
  }

  checkOnlineOffline() {
    // if the user is not on middle of the update reload the page when the device comes back online

    this.isAppOnline = navigator?.onLine;

    let offlineReloadCheck = this.offlineMode;
    this.offlineMode = !navigator?.onLine;

    if (
      this.offlineMode == false &&
      offlineReloadCheck &&
      this.isHomeComponent
    ) {
      this.appService.getCountries().subscribe((response: any) => {
        if (response['status'] == 'success') {
          localStorage.setItem('syncData', 'true');
          window.location.reload();
        } else {
          setTimeout(() => {
            localStorage.setItem('syncData', 'true');
            window.location.reload();
          }, 2000);
        }
      });
    }

    if (this.offlineMode && !this.isOnEvent) {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: `Disconnected from Internet. Running in offline mode.`,
          });
        }, 100);

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
      });
    }
  }
  syncQueuedData() {
    setTimeout(() => {
      if (!this.isInBackgroundSync) {
        this.isInBackgroundSync = true;

        // ask the service worker to start sync
        navigator?.serviceWorker?.controller?.postMessage({
          type: 'syncData',
        });
        // send a fallbackcheck to see background sync is complete
        setTimeout(() => {
          this.fallBackCheck();
        }, 2000);
      }
    }, 100);
  }
  fallBackCheck() {
    /// a delayed to check whether the background sync is over or not
    if (this.isInBackgroundSync) {
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'syncDataCheck',
      });
    }
  }
  serviceWorkerOperations() {
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PAGES_PORT',
      },
      [this.messageChannel.port2]
    );

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event?.data?.type === 'PUSH') {
        this.getNotificationCount(true);
      } else if (event?.data?.type === 'queuedActionCheck') {
        if (
          event?.data?.data &&
          !this.isInBackgroundSync &&
          navigator?.onLine
        ) {
          this.syncQueuedData();
        }
      } else if (event?.data?.type === 'syncDataCheck') {
        if (this.isInBackgroundSync) {
          if (event?.data?.syncInProgress) {
            setTimeout(() => {
              this.fallBackCheck();
            }, 5000);
          } else {
            this.isInBackgroundSync = false;
          }
        }
      }
    });

    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.type === 'syncComplete') {
        if (this.isInBackgroundSync) {
          this.isInBackgroundSync = false;
        }
      }

      if (event?.data?.message) {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: event?.data?.message,
        });
      }
      if (event?.data?.message) {
        this.ngZone.run(() => {
          if (event?.data?.status === 'success') {
            this.toasterService.setMessage({
              successMessage: event?.data?.message,
              errorMessage: '',
            });
          } else {
            this.toasterService.setMessage({
              errorMessage: event?.data?.message,
              successMessage: '',
            });
          }
        });
      }
    };

    if (localStorage.getItem('syncData') === 'true' && navigator?.onLine) {
      localStorage.removeItem('syncData');
      this.syncQueuedData();
    }
  }

  async ngOnInit() {
    if (this.isSuperUser !== true && this.userData?.access) {
      await this.getSideBar();
    }
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
      });

      document.addEventListener('gesturechange', function (e) {
        e.preventDefault();
      });
      document.addEventListener('gestureend', function (e) {
        e.preventDefault();
      });
    } else {
      document.addEventListener('touchstart', function (e) {
        document.body.style.touchAction = 'manipulation';
      });
    }

    this.pageService.isOnEvent.subscribe((isOnEvent: any) => {
      this.ngZone.run(() => {
        this.isOnEvent = isOnEvent;
        this.checkOnlineOffline();
        this.checkWelfareChecks();
      });
    });
    this.pageService.sWOperations.subscribe((operation: any) => {
      if (operation?.syncData) {
        this.syncQueuedData();
      }
    });
    this.pageService.miscSubjectParam.subscribe((value: any) => {
      if (value?.sosAlertActive === 'turnOff') {
        this.stopAlert();
      }
    });
    this.appService.permissionErrorAlert.subscribe((value: any) => {
      this.modifyAppStatus(value);
    });
    this.deviceInforService.permissionErrorAlert.subscribe((value: any) => {
      this.modifyAppStatus(value);
    });
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'queuedActionCheck',
    });

    window.addEventListener('online', this.checkOnlineOffline.bind(this));
    window.addEventListener('offline', this.checkOnlineOffline.bind(this));

    this.serviceWorkerOperations();

    if (this.isSuperUser == true) {
      this.userMenu = superUserMenu;
    }

    if (window.innerWidth >= 1200) {
      this.expandIcon = true;
    }

    let userData = this.appService.getUserData();
    // this.currentTheme = userData?.preferences?.theme || 'default';

    this.updateLoggedInUserDetail();
    if (!this.offlineMode) {
      this.userAppChecks();
      if (localStorage.getItem('loginSuccess') === 'true') {
        setTimeout(() => {
          this.afterLoginActions();
        }, 1000);
      }
    }
    this.removeStaleIndexedDbItems();
  }
  showWelfareButton(response: any) {
    this.welfareTempHoldValue = !this.isAdmin
      ? this.isClockedIn
        ? response?.pending_welfare_check
        : false
      : response?.pending_welfare_check;
    this.checkWelfareChecks();
  }
  updateLoggedInUserDetail() {
    let userData = this.appService.getUserData();
    const firstName = this.userData?.profile?.first_name;
    const lastName = this.userData?.profile?.last_name;
    const firstLetterFirstName = firstName ? firstName.charAt(0) : '';
    const firstLetterLastName = lastName ? lastName.charAt(0) : '';
    const initialName = `${firstLetterFirstName}${firstLetterLastName}`;
    this.loggedInUser = {
      profile: userData?.profile?.profile_image_url,
      name: `${this.userData?.profile?.first_name} ${this.userData?.profile?.last_name}`,
      initialName: initialName,
    };
  }

  getNotificationCount(showMessage = false) {
    this.profileService.getNotificationCount().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.notificationCount = response?.count;

        this.getSOSAlerts(response?.sos_alerts || 0);
        this.showWelfareButton(response?.data);
        this.updateClientList(response?.client_checksum);
        this.appVersionCheck(response?.data?.last_item);

        if (showMessage && this.notificationCount) {
          this.toasterService.setMessage({
            successMessage: `You have a new notification`,
            errorMessage: '',
          });
        }
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  logOut() {
    if (this.isSuperUser === true) {
      this.appService.logOut();
    } else {
      this.spinnerService.show();
      let userData = this.appService.getUserData();

      this.profileService
        .logOut({ theme: userData?.preferences?.theme })
        .then((response: any) => {
          if (response?.status === 'success') {
            this.appService.logOut();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  toggleSidebar(event: any): boolean {
    event.preventDefault();
    // this.sidebarService.toggle(true, 'menu-sidebar');
    this.expandIcon = !this.expandIcon;
    document.body.classList.toggle('sidebar-open');
    return false;
  }
  navigateHome() {
    // this.menuService.navigateHome();
    this.router.navigate(['/dashboard']);
    document.body.classList.remove('sidebar-open');
    return false;
  }
  scrollToTop() {
    // this.scrollService.scrollTo(0, 0);
    this.windowScrolled = false;
  }
  refreshPage() {
    if (navigator?.onLine) {
      window.location.reload();
    }
  }
  async openNotifcationTemplate(notificationPopover: TemplateRef<any>) {
    if (!(await this.permissionsService.checkNotificationPermission())) {
      this.modifyAppStatus('notificationDisabled');
    }
    this.notificationPermission =
      await this.permissionsService.checkNotificationPermission();
    this.rows = 10;
    this.previous = 0;
    this.pageNum = 1;
    this.getNotifications();
    this.dialogRef = this.dialogService.open(
      notificationPopover,
      {},
      this.viewContainerRef
    );
  }

  getNotifications() {
    let params: any = {};
    if (this.isSuperUser != true) {
      if (this.rows) {
        params['rows'] = this.rows;
      }
      if (this.previous) {
        params['previous'] = this.previous;
      }
      this.profileService
        .getNotifications(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.notificationData = response['data'];
            this.totalRows = response?.total_count;
            this.totalPages = Math.ceil(this.totalRows / this.rows);

            this.notificationCount - this.totalRows;
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  onChangeNotificationPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getNotifications();
  }
  redirectNotification(notification: any) {
    if (
      this.isAdmin ||
      ((this.isGuardUser || this.isDispatchUser) && this.isClockedIn)
    ) {
      if (notification?.event_id) {
        if (['job'].includes(notification?.event_type)) {
          this.spinnerService.show();
          this.jobService
            .getJobById(notification.event_id)
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                let job = response['data'];
                this.router.navigateByUrl(`/job-detail/${job.job_key}`, {
                  state: job.id,
                });
                window.localStorage.setItem('urlId', job.id);
                this.spinnerService.hide();
              } else {
                this.spinnerService.hide();
              }
            });
        } else if (notification?.event_type === 'user_license') {
          this.router.navigate(['/profile']);
        } else if (notification?.event_type === 'company_license') {
          this.router.navigate(['/company-profile']);
        } else if (notification?.event_type === 'patrol_job') {
          this.router.navigate([
            '/view-route',
            {
              rKey: String(notification?.event_id),
            },
          ]);
        }
      } else {
        if (notification?.event_type === 'version_release') {
          this.openChangeLog();
        } else if (notification?.event_type === 'feedback') {
          this.router.navigate(['/support']);
        } else if (
          notification?.event_type === 'welfare_check' &&
          (this.isAdmin || this.isDispatchUser)
        ) {
          this.router.navigate(['/missed-events']);
        }
      }
      this.deleteNotification(notification);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  clearNotifications() {
    this.spinnerService.show();
    this.profileService
      .deleteNotifications({ clear_all: true }, {})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = [];
          // this.popover?.hide();
          this.getNotificationCount();
          // this.getNotifications();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  deleteNotification(notification: any) {
    this.profileService
      .deleteNotifications({}, { id_list: [notification.id] })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = this.notificationData.filter(
            (item: any) => !(item.id === notification.id)
          );
          if (!this.notificationData?.length) {
            this.dialogRef?.close();
          }
          this.notificationCount = this.notificationData?.length;
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  addfeedback() {
    this.spinnerService.show();
    const feedbackValue: any = this.feedbackForm.get('feedback')?.value;
    let fileData: FormData = new FormData();
    fileData.append('file', this.feedbackScreenShot);
    fileData.append('feedback', feedbackValue);
    fileData.append(
      'app_version',
      String(sessionStorage.getItem('appVersion') || '')
    );
    this.appService
      .formDataApi(`feedback/submit_feedback`, fileData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.feedbackForm.reset();
          this.dialogRef.close();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  handleDragStart(event: any): void {
    this.dragging = true;
  }
  onDragEnded(event: any): void {}
  showHideDraggableFeedbackBtn(value: any) {
    if (value === true) {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'hidden';
    } else {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'visible';
    }
  }

  enableScrollOnBody() {
    document.body.classList.remove('sidebar-open');
  }
  installButton() {
    this.spinnerService.show();
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      this.dialogRef = this.dialogService.open(PwaInstallationGuideComponent, {
        data: {},
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {});
      this.toasterService.setMessage({
        successMessage:
          'To install this app on your device,Please follow the instructions/screenshots shown below.',
        errorMessage: '',
      });

      this.spinnerService.hide();
    } else {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.spinnerService.hide();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
          }

          // Clear the deferredPrompt variable
          this.deferredPrompt = null;

          // Hide the install button after installation
        });
      } else {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage:
            'Something went wrong, Please check if app is already installed!',
        });
      }
    }
  }

  capture(feedBackType: any, requestRef: TemplateRef<any>) {
    this.spinnerService.show();

    this.feedbackForm.controls['feedback'].setValue(null);

    if (feedBackType == 'draggableBtn') {
      this.showHideDraggableFeedbackBtn(true);
      if (this.dragging) {
        this.dragging = false;
      }
    }
    const device = this.deviceService.getDeviceInfo();

    // if (device?.device !== 'iPhone') {
    //   this.captureScreenShot();
    // }
    setTimeout(() => {
      this.spinnerService.hide();
      this.dialogRef = this.dialogService.open(
        requestRef,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }, 100);
    this.appService
      .getCurrentVersionInfo()
      .then((data: any) => sessionStorage.setItem('appVersion', data?.version));

    // this.spinnerService.hide();
    this.dialogRef.afterClosed().subscribe(() => this.feedbackForm.reset());
  }

  // async captureScreenShot() {
  //   try {
  //     let section = document.querySelector('#mainContainer');
  //     const canvas = await html2canvas(section, {
  //       logging: false,
  //       useCORS: true,
  //     }).then((canvas: any) => {
  //       const arr = canvas.toDataURL().split(',');
  //       const bstr = atob(arr[1]);
  //       let n = bstr.length;
  //       const u8arr = new Uint8Array(n);
  //       while (n--) {
  //         u8arr[n] = bstr.charCodeAt(n);
  //       }
  //       this.spinnerService.hide();
  //       this.feedbackScreenShot = new File([u8arr], `Feedback`, {
  //         type: 'image/jpeg',
  //       });
  //     });
  //   } catch (error) {
  //     console.error('Error capturing screenshot:', error);
  //   }
  // }
  clockedInOut() {
    this.spinnerService.show();
    let params = {};
    if (this.isClockedIn === true) {
      this.spinnerService.hide();
      params = {
        clock_out: 1,
      };
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Clock Out',
          message: 'Confirm you want to clock off.',
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        if (value === true) {
          this.spinnerService.show();
          this.profileService
            .guardClockedInOut(params)
            .then((response: any) => {
              if (response?.status == 'success') {
                this.spinnerService.hide();
                this.toasterService.setMessage({
                  successMessage: response?.message,
                  errorMessage: '',
                });
                let userData = this.appService.getUserData();
                userData.user_token = response?.user_token;
                this.appService.setUserData(userData);
              } else {
                this.toasterService.setMessage({
                  successMessage: '',
                  errorMessage: response?.message,
                });
                this.spinnerService.hide();
              }
              setTimeout(() => {
                window.location.reload();
              });
            });
        }
      });
    } else {
      this.profileService.guardClockedInOut(params).then((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response?.message,
            errorMessage: '',
          });
          let userData = this.appService.getUserData();
          userData.user_token = response?.user_token;
          this.appService.setUserData(userData);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
        setTimeout(() => {
          window.location.reload();
        });
      });
    }
  }
  welfareCheckIn() {
    this.spinnerService.show();
    this.jobService.welfareCheckIn().subscribe((response: any) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: response?.message,
          errorMessage: '',
        });
        this.showWelfareCheckButton = false;
        this.welfareTempHoldValue = false;
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
        this.spinnerService.hide();
      }
    });
  }

  async userAppChecks() {
    //check whther user has set password, first name last name etc
    if (!this.isSuperUser) {
      if (
        this.dataCheckService.hasPasswordSet() === false ||
        window.localStorage.getItem('resetPasswordPWA')
      ) {
        const dialogRefUser = this.dialogService.open(AppChecksComponent, {
          data: {},
        });
        dialogRefUser.afterClosed().subscribe(async (value: any) => {
          this.spinnerService.hide();
          window.location.reload();
          await this.getSideBar();
        });
      }
    }
  }
  toggleSideBarMenu() {
    this.showUserDropdown = false;
    this.navbarToggled = !this.navbarToggled;
    // Add or remove the no-scroll class based on menu state
    if (this.navbarToggled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
  toggleManageMenu() {
    this.showManageMenu = !this.showManageMenu;
  }
  toggleUserProfileMenu() {
    this.showUserDropdown = !this.showUserDropdown;
  }

  redirectToPage(menuLink: string, userMenu: boolean = false) {
    if (menuLink === 'signOut') {
      if (this.isGuardUser && this.isClockedIn) {
        this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Sign Out',
            message: 'This will also clock you out.',
          },
        });
        this.dialogRef.afterClosed().subscribe((value: any) => {
          if (value === true) {
            let params = {
              clock_out: 1,
            };
            this.spinnerService.show();
            this.profileService
              .guardClockedInOut(params)
              .then((response: any) => {
                if (response?.status == 'success') {
                  this.spinnerService.hide();
                  this.toasterService.setMessage({
                    successMessage: response?.message,
                    errorMessage: '',
                  });
                  let userData = this.appService.getUserData();
                  userData.user_token = response?.user_token;
                  this.appService.setUserData(userData);
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response?.message,
                  });
                  this.spinnerService.hide();
                }
                // setTimeout(() => {
                //   window.location.reload();
                // });
              });
            this.logOut();
          }
        });
      } else {
        this.logOut();
      }
    } else if (menuLink === 'updateApp') {
      this.openChangeLog();
    } else if (menuLink === 'clockOut') {
      this.clockedInOut();
    } else if (menuLink === 'clockIn') {
      this.clockedInOut();
    } else if (menuLink === 'changeAccount') {
      const fileImportDialogRef = this.dialogService.open(
        MultipleSubscribersComponent,
        {}
      );
      fileImportDialogRef.afterClosed().subscribe((value) => {
        if (value !== 'close') {
          window.location.reload();
        }
      });
    } else {
      this.router.navigate([menuLink]);
    }
    if (window.innerWidth < 1200) {
      this.toggleSideBarMenu();
    }
    this.showManageMenu = false;
    this.showUserDropdown = false;
    this.navbarToggled = false;
  }

  handleWrapperClick(event: Event) {
    // Prevent the wrapper click event from interfering with child elements
    event.stopPropagation();
    if (!this.largeView) {
      if (this.navbarToggled === true) {
        this.navbarToggled = false;
      }
    }
    if (this.navbarToggled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  checkPrevouslySubscribed() {
    return (
      !this.isAdmin ||
      this.dataCheckService.checkIsPreviouslySubscribedAndSubscriptionInActive()
    );
  }
  afterLoginActions() {
    this.callNearestApis();
    this.requestSubscription();
    localStorage.removeItem('loginSuccess');
  }
  // call the job/cp/cleint apis to build the indexed db cache

  callNearestApis() {
    try {
      this.jobService.getNearestCheckpointList().then((response: any) => {});
      this.jobService.getNearesSiteList().then((response: any) => {});
      this.jobService
        .getNearestJobs({
          time_offset: new Date().getTimezoneOffset(),
        })
        .subscribe((response: any) => {});
    } catch (error) {}
  }
  async checkExisitingSubscription() {
    return new Promise((resolve) => {
      this.swPush.subscription.subscribe((res: any) => {
        resolve(res);
      });
    });
  }
  async unsubscribeFromPush() {
    try {
      await this.swPush.unsubscribe();
      // Successfully unsubscribed
    } catch (err) {}
  }
  requestSubscription = async () => {
    if (!this.swPush.isEnabled) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Warning: You have not enabled push notifications',
      });
      return;
    }
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapIDPKey,
      })
      .then((_) => {
        this.profileService
          .registerPushNotification({ subscription: JSON.stringify(_) })
          .then((response: any) => {
            if (response['status'] == 'success') {
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      })
      .catch(async (_: any) => {
        let existingSub = await this.checkExisitingSubscription();
        if (existingSub) {
          await this.unsubscribeFromPush();
          await this.requestSubscription();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: _,
          });
        }
      });
  };
  async appVersionCheck(lastItem: any) {
    const versionData: any = await this.appService.appVersionCheck({
      last_item: lastItem,
    });
    const latestVersion = versionData?.latestVersion;
    const currentVersion = versionData?.currentVersion;

    if (
      currentVersion?.version &&
      latestVersion?.last_item?.version !== currentVersion?.version
    ) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
      this.appService.updateApp();
    }
    if (!currentVersion) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
    }
  }

  async removeStaleIndexedDbItems() {
    try {
      const filterDate = new Date();
      filterDate.setDate(filterDate.getDate() - 3);

      if (this.swPush.isEnabled) {
        const result = await gTDB.gtSavedData.toArray();
        const staleRecords = result
          ?.filter(
            (item: any) =>
              new Date(item?.sync_time).getTime() < filterDate.getTime()
          )
          ?.map((item: any) => item?.indexDBId);

        if (staleRecords?.length) {
          this.deleteCachedData(staleRecords);
        }
      }
    } catch (error) {}
  }

  deleteCachedData(keys: any) {
    try {
      gTDB.gtSavedData.bulkDelete(keys);
    } catch (error) {
      console.log(error);
    }
  }
  updateQRCheckPointList(qrCpCheckSum: any) {
    if (qrCpCheckSum?.qr_checkpoint_count === 0) {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({
              shortPath: '/api/check_point/list_qr_checkpoints/',
            })
            .delete();
        }
      } catch (error) {}
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/check_point/list_qr_checkpoints/' })
            .toArray()
            .then((response: any) => {
              const checkpointData: any = response?.[0]?.data || [];
              const cachedQrCheckpointCount: number = checkpointData?.length;
              const lastUpdatedDate: any = checkpointData?.[0]?.last_updated_at
                ? new Date(checkpointData?.[0]?.last_updated_at)
                : null;
              if (
                qrCpCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(qrCpCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }

              if (
                cachedQrCheckpointCount !== qrCpCheckSum?.qr_checkpoint_count
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {
        console.log(error, 'ERROR');
      }
    }
  }
  updateClientList(clientCheckSum: any) {
    if (clientCheckSum?.company_count === 0) {
      if (this.swPush.isEnabled) {
        gTDB.cachedData
          .where({
            shortPath: '/api/company/list_all_companies/',
          })
          .delete();
      }
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/company/list_all_companies/' })
            .toArray()
            .then((response: any) => {
              const clientData: any = response?.[0]?.data || [];
              const cachedClientCount: number = clientData?.length;
              const lastUpdatedDate: any = clientData?.[0]?.last_updated_at
                ? new Date(clientData?.[0]?.last_updated_at)
                : null;
              if (
                clientCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(clientCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }

              if (cachedClientCount !== clientCheckSum?.company_count) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {
        console.log(error, 'ERROR');
      }
    }
  }
  lockScreen() {
    this.orientationLockService.lockOrientation();
  }
  showSOSAlerts() {
    this.router.navigate([
      '/sos-alerts',
      { alertCounts: String(this.sosAlertCount) },
    ]);
  }
  getSOSAlerts(alertCount: number = 0) {
    if (alertCount == 0) {
      this.isAlertActive = false;

      this.sosAlertCount = 0;
      this.beepIntervalSubscription?.unsubscribe();
      this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertMuted' });
    } else if (
      alertCount != this.sosAlertCount &&
      (this.isAdmin || this.isDispatchUser)
    ) {
      this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertActive' });
      this.isAlertActive = true;
      this.alertUserWithSoundFeedback();
      this.sosAlertCount = alertCount;
      this.router.navigate([
        '/sos-alerts',
        { alertCounts: String(alertCount) },
      ]);
    }
  }

  stopAlert() {
    this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertMuted' });
    this.isAlertActive = false;
    this.beepIntervalSubscription?.unsubscribe();
  }
  alertUserWithSoundFeedback() {
    this.beepIntervalSubscription = interval(1000)
      .pipe(takeWhile(() => this.isAlertActive))
      .subscribe((x) => {
        try {
          window.navigator.vibrate(200);
        } catch (error) {
          console.log(error, 'ERROR');
        }
        this.toasterService.playChord(200, [1000], 100);
      });
  }
  modifyAppStatus(value: string) {
    const statusMsg = this.isInBackgroundSync
      ? 'Status: Synchronizing Data'
      : this.isAppOnline
      ? 'Status: Online'
      : 'Status: Offline';

    switch (value) {
      case 'locationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\nError: Location access not given`,
        };
        break;
      case 'cameraDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\n\nError: Camera access not given`,
        };
        break;
      case 'notificationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\nError: Notification access not given`,
        };
        break;
    }

    this.cdr.detectChanges();
    const tooltipElement = document.getElementById('customTooltipBtn');

    if (tooltipElement) {
      // Dispose of the existing tooltip instance if it exists
      const existingTooltip = bootstrap.Tooltip.getInstance(tooltipElement);
      if (existingTooltip) {
        existingTooltip.dispose();
      }

      const tooltipInstance = new bootstrap.Tooltip(tooltipElement);
      tooltipInstance.setContent({
        '.tooltip-inner': this.permissionErrorStatus?.message,
      });
      tooltipInstance?.show();
      setTimeout(() => {
        tooltipInstance?.hide();
      }, 1000);
    }
  }
}
