import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  getInitalDateRangeForListData,
  isLargeScreen,
} from '../../../../global.variable';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../shared/components/contact/contact.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import { ClientsService } from '../clients/clients.service';
import { JobsService } from '../jobs/jobs.service';
import { getDateTimeRange } from '../tableDetails';
import { UserPromptService } from '../user-prompts/user-prompt.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
})
export class ReportsComponent implements OnInit {
  largeView: Boolean = isLargeScreen;
  billingReportEmailForm: UntypedFormGroup = new UntypedFormGroup({
    emails: new UntypedFormControl('', [Validators.required, Validators.email]),
    file_name: new UntypedFormControl(''),
  });

  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  patrolTableDetailMobileView: any = {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        header: 'Event Time',
        ...getDateTimeRange(),
        sortKey: 'updated_at',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'end',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },

      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  patrolTableDetail: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      dateRangeTimePicker: true,
      ...getDateTimeRange(),
    },
    {
      header: 'Event Type',
      name: 'action',
    },

    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ];
  userEventDetailsTableView = [...this.patrolTableDetail];

  userEventDetails = { ...this.patrolTableDetailMobileView };

  jobReportTableDetailsMobile = {
    individualDetails: [
      {
        name: 'job_key',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        name: 'created_at',
        datetimeObj: true,
        dateRangeKey: 'created_at',
        ...getDateTimeRange(),
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-weight': 'bold',
        },
      },

      {
        name: 'site_address',
        cardRowStyle: {
          'font-size': '0.6rem',
          'font-weight': 'bold',
        },
      },

      {
        name: 'job_type',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'ext_job_id',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
    ],
  };

  jobCompletedReportTableDetails = [
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      ...getDateTimeRange(),
    },

    {
      header: 'Onsite',
      name: 'on_site_date',
      datetimeObj: true,
    },
    {
      header: 'Offsite',
      name: 'off_site_date',
      datetimeObj: true,
    },
    {
      header: 'Time Onsite',

      nestedValue: (row: any) => {
        return this.getTimeDifference(row);
      },
    },
    {
      header: 'Completed',
      sortKey: 'latest_update',
      name: 'completed_date',
      datetimeObj: true,
    },
    {
      header: 'Site Name',
      name: 'site_name',
    },
    {
      header: 'Address',
      name: 'site_address',
      searchKey: 'site_address',
      columnDataStyle: {},
    },
    {
      header: 'Job Type',
      name: 'job_type',
    },
    {
      header: 'Job ID',
      name: 'job_key',
    },
    {
      header: 'Ext. Job ID',
      name: 'ext_job_id',
    },
    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );

        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );

        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];
  jobCancelledReportTableDetails = [
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      ...getDateTimeRange(),
    },
    {
      header: 'Cancelled',
      sortKey: 'latest_update',
      name: 'cancelled_date',
      datetimeObj: true,
    },
    {
      header: 'Cancel Reason',
      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.length > 0 ? comments[comments.length - 1] : '';
      },
    },
    {
      header: 'Site Name',
      name: 'site_name',
    },
    {
      header: 'Address',
      name: 'site_address',
      searchKey: 'site_address',
      columnDataStyle: {},
    },
    {
      header: 'Job Type',
      name: 'job_type',
    },
    {
      header: 'Job ID',
      name: 'job_key',
    },
    {
      header: 'Ext. Job ID',
      name: 'ext_job_id',
    },
    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];

  dateRange: any = {
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
  };

  userEvents: any;

  selectedEvent: any;

  selectedEventType: string = 'patrol';
  sortKeys: any = [];
  selectedClient: any;
  clientDropDownList: any;

  searchParams: any;
  totalRows: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  scrollPosition: any;

  dialogeRef: any;

  allowPDFExport: boolean = false;
  allowBillingReportExport: boolean = false;

  selectedUsers: any = [];
  usersList: any = [];
  newComment: any;
  dialogref: any;

  previousComment: any;
  uiTabs = {
    tab1: 'Patrol Reports',
    tab2: 'Job Reports',
    tab3: 'Discrepancy Reports',
  };

  jobReportTypes = ['Completed', 'Cancelled'];
  selectedJobReportType: String = 'Completed';

  activeTab: string = this.uiTabs.tab1;
  otherParams: any = {};
  userPrompts: any = [];
  selectedPromptIds: any = [];

  constructor(
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private jobService: JobsService,
    private customerService: ClientsService,
    private dialogService: ModelDialogueService,
    private cdr: ChangeDetectorRef,
    private checkpointService: CheckpointService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private userPromptService: UserPromptService
  ) {
    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetails?.individualDetails || []
    );
  }

  ngOnInit(): void {
    if (this.selectedEventType === 'patrol') {
      this.sortKeys = ['updated_at'];
    }
    this.getHomeScreenPrompts();
  }
  jobReportSelectRadio(event: any) {
    this.jobReportChange(event.target.value);
  }
  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }
  jobReportChange(jobReportType: any) {
    this.selectedJobReportType = jobReportType;
    if (this.selectedJobReportType === 'Completed') {
      this.userEventDetailsTableView = this.jobCompletedReportTableDetails;
      this.userEventDetails = this.jobReportTableDetailsMobile;
    } else {
      this.userEventDetailsTableView = this.jobCancelledReportTableDetails;
      this.userEventDetails = this.jobReportTableDetailsMobile;
    }
    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetails?.individualDetails || []
    );
    this.getUserUpdateLog();
  }
  getTimeDifference(rowData: any) {
    const { on_site_date, off_site_date } = rowData;

    // * onsite and offsite time exists; else return null
    // * onsite and offsite hour and minute match || onsite > offsite; return 0;
    // * onsite < offsite; return difference

    if (off_site_date && on_site_date) {
      let date1 = new Date(on_site_date);
      let date2 = new Date(off_site_date);

      if (
        date1 > date2 ||
        (date1.getHours() === date2.getHours() &&
          date1.getMinutes() === date2.getMinutes())
      ) {
        return 0;
      }

      const diffInMs = date2.getTime() - date1.getTime();
      const diffInHours = diffInMs / (1000 * 60 * 60);
      const hours = Math.floor(diffInHours);
      const minutes = Math.round((diffInHours - hours) * 60);

      // Pad the hours and minutes with leading zeros
      const hours_str = hours < 10 ? '0' + hours : hours;
      const minutes_str = minutes < 10 ? '0' + minutes : minutes;

      return `${hours_str}:${minutes_str}`;
    } else {
      return 0;
    }
  }

  getUserUpdateLog() {
    if (this.activeTab === this.uiTabs.tab1 && !this.selectedClient?.id) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Select a Client',
      });
      return;
    }
    this.spinnerService.show();
    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      company_id: this.selectedClient?.id,
      time_offset: timezoneOffset.getTimezoneOffset(),
      user_prompt_ids: this.selectedPromptIds,
    };
    if (this.dateRange) body.date_range = this.dateRange;

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;

    if (this.pageSize) {
      body['rows'] = this.pageSize;
    }
    if (this.previous) {
      body['previous'] = this.previous;
    }
    if (this.selectedJobReportType) {
      body['job_report_type'] = this.selectedJobReportType?.toLowerCase();
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.allowPDFExport = true;
        this.allowBillingReportExport = true;
        this.selectedEvent = null;
        this.spinnerService.hide();
        this.totalRows = response['total_size'];
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: Math.ceil(this.totalRows / this.pageSize),
        };
        this.userEvents = response['data'];

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  clearAll() {
    this.selectedClient = null;

    this.totalPages = 0;
    this.selectedUsers = [];
    this.selectedPromptIds = [];
    this.billingReportEmailForm.reset();
    this.userEvents = [];
    this.otherParams.paginationData = {};

    if (this.selectedEventType === 'job_report') {
      this.dateRange = null;
      this.sortKeys = [];
      this.dateRange = getInitalDateRangeForListData(
        this.userEventDetails?.individualDetails || []
      );
    } else {
      this.sortKeys = ['updated_at'];
      this.dateRange = getInitalDateRangeForListData(
        this.userEventDetails?.individualDetails || []
      );
    }
  }

  onTableRowClick(event: any) {
    this.scrollPosition = window.scrollY;

    this.selectedEvent = event;

    window.scrollTo(0, document.body.scrollHeight);
  }

  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getUserUpdateLog();
  }
  searchColumn(event: any) {
    this.dateRange = null;
    this.searchParams = event?.searchParams || {};
    this.getUserUpdateLog();
  }
  onClientSearch(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.clientDropDownList?.length)
    ) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.clientDropDownList = res?.data?.filter(
              (client: any) => client?.id != this.selectedClient?.id
            );
          }
        });
    }
  }
  onClientSelect(data: any) {
    this.clearAll();
    setTimeout(() => {
      this.cdr.markForCheck();
      if (data != this.selectedClient) {
        this.selectedClient = data;

        if (this.selectedClient) {
          this.searchData();
        }
      }
    }, 100);
  }
  searchData() {
    this.selectedPromptIds = [
      ...new Set(
        this.userPrompts
          ?.filter((item: any) => item?.selected)
          ?.map((item: any) => item?.id)
      ),
    ];
    if (this.selectedClient) {
      this.getUserUpdateLog();
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Select a Client',
      });
    }
  }
  exportData(exportType: any) {
    this.spinnerService.show();
    let userIds = [...new Set([...this.selectedUsers].map((item) => item.id))];
    if (
      !(userIds?.length || this.selectedPromptIds?.length) &&
      this.selectedEventType === 'patrol'
    ) {
      this.spinnerService.hide();
      return;
    }
    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      company_id: this.selectedClient?.id,
      export_type: exportType,
      user_ids: userIds,
      time_offset: timezoneOffset.getTimezoneOffset(),
      user_prompt_ids: this.selectedPromptIds,
    };

    //TODO: Check the date range and make the default date range if date range filter is cleared
    // if (!this.dateRange) {
    //   this.dateRange = {
    //     start: new Date(new Date().setDate(new Date().getDate() - 1)),
    //     end: new Date(new Date().setDate(new Date().getDate() + 1)),
    //   };
    // }
    if (this.dateRange) body.date_range = this.dateRange;

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;

    this.userService.exportUserUpdateLog(body).then((response: any) => {
      if (response['status'] == 'success') {
        this.allowPDFExport = false;
        this.toasterService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
        this.clearAll();

        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  onChangePagination(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    this.pageSize = data?.paginationData?.rows;

    this.getUserUpdateLog();

    this.otherParams.paginationData = { ...data?.paginationData };
  }

  hideDetails() {
    this.selectedEvent = null;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }

  openTemplate(template?: any) {
    this.dialogeRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  removeUser(deleteUser: any): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user: any) => user?.id !== deleteUser?.id
    );
  }

  addUser(id: string, selectedUser: any): void {
    if (selectedUser?.key === 0) {
      this.addToContactsTemp();
    } else {
      if (this.selectedUsers.some((user: any) => user?.id === id)) {
        this.toasterService.setMessage({
          errorMessage: 'User already added',
          successMessage: '',
        });
      } else {
        this.selectedUsers.push({
          id: id,
          name: selectedUser?.user_full_name
            ? selectedUser.user_full_name
            : selectedUser.user_email,
          email: selectedUser?.email,
          mobile_number: selectedUser?.mobile_number,
        });
        this.usersList = this.usersList?.filter(
          (value1: any) =>
            !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
        );
      }
    }
  }
  getUsers(event: any = null) {
    let value = event?.target?.value;
    let params: any = { union: 1, company_id: this.selectedClient?.id };
    if (value) {
      params['search_str'] = value;
    }

    this.customerService
      .searchLocationContacts(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          let users = response['data'];
          users = users.filter(
            (value1: any) =>
              !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
          );
          this.usersList = users;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  editComment(commentData: any, requestRef: any) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogref = this.dialogService.open(
      requestRef,
      {
        data: commentData,
      },
      this.viewContainerRef
    );
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
      };
      this.checkpointService.updateClient(body).then((response: any) => {
        this.getUserUpdateLog();
        this.newComment = '';
        this.selectedEvent = null;
        this.spinnerService.hide();
      });
    }
  }

  addToContactsTemp() {
    let clientData: any = this.selectedClient;
    this.dialogref = this.dialogService.open(ContactComponent, {
      data: {
        clientData: clientData,

        patrolReportUsers: true,
        contactType: 'lc',
      },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      this.spinnerService.hide();

      if (value !== 'false') {
        if (value?.length) {
          let lastAddedContact = value[value?.length - 1];

          if (lastAddedContact)
            this.addUser(
              lastAddedContact?.id,

              lastAddedContact
            );
        }
      }
    });
  }
  changeTab(event: any) {
    this.activeTab = event;
    this.selectedEvent = null;
    this.clearAll();
    this.userEventDetailsTableView = [...this.patrolTableDetail];
    this.userEventDetails = { ...this.patrolTableDetailMobileView };
    if (this.activeTab === this.uiTabs.tab1) {
      this.selectedEventType = 'patrol';
      this.sortKeys = ['updated_at'];
    } else if (this.activeTab === this.uiTabs.tab2) {
      this.sortKeys = [];
      this.searchParams = null;
      this.selectedEventType = 'job_report';

      this.jobReportChange(this.selectedJobReportType);
    } else if (this.activeTab === this.uiTabs.tab3) {
      this.selectedEventType = 'exceptions';
      this.sortKeys = ['updated_at'];

      this.userEventDetailsTableView = this.userEventDetailsTableView?.filter(
        (item: any) => item?.name !== 'action'
      );

      this.userEventDetailsTableView.splice(-1, 0, {
        header: 'Client',
        name: 'company_name',
      });
      this.getUserUpdateLog();
    }
  }
  onJobTableRowClick(event: any) {
    this.spinnerService.show();
    this.router.navigateByUrl(`/job-detail/${event?.job_key}`, {
      state: event?.job_id,
    });
    window.localStorage.setItem('urlId', event?.job_id);
  }
  mailBillingReport() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Send Report to your email',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        let timezoneOffset = new Date();
        let body: any = {
          event_type: this.selectedEventType,
          export_type: 'csv',
          time_offset: timezoneOffset.getTimezoneOffset(),
          job_report_type: this.selectedJobReportType?.toLowerCase(),
        };

        if (this.dateRange) body.date_range = this.dateRange;

        if (this.sortKeys) body.sort_key = this.sortKeys;
        if (this.searchParams) body.search_filters = this.searchParams;

        this.userService.exportUserUpdateLog(body).then((response: any) => {
          if (response['status'] == 'success') {
            this.allowBillingReportExport = false;
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.clearAll();

            this.spinnerService.hide();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
      }
    });
  }
}
