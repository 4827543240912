<div class="col-xs-12 col-lg-6 mb-2">
  <div class="form-floating my-3">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="promptName"
      placeholder="Prompt Name"
    />
    <label>Prompt Name</label>
  </div>

  <ng-container *ngIf="promptName || paginatedPromptData?.length">
    <ng-container *ngIf="paginatedPromptData?.length > 1">
      <div class="py-2 border-top border-5 strong row">
        <div class="col-lg-4 mb-1">
          <button
            nbButton
            [disabled]="page === 0"
            (click)="changePagination(false)"
            class="mx-3"
          >
            <
          </button>

          <span>
            Page {{ page + 1 }} of {{ paginatedPromptData?.length }}
          </span>
          <button
            nbButton
            [disabled]="page + 1 === paginatedPromptData?.length"
            (click)="changePagination()"
            class="mx-3"
          >
            >
          </button>
        </div>
      </div>
    </ng-container>
    <div class="row row-cols-2">
      <div class="d-flex align-items-center">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="showOnHome"
            (change)="showOnHome = !showOnHome"
            id="showOnHomeCheckbox"
          />
          <label class="form-check-label" for="showOnHomeCheckbox">
            Show on Home
          </label>
        </div>
        <span
          class="ms-1"
          tabindex="0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="When box is ticked, guards will be able to trigger prompts manually from home page and link to a client."
        >
          <i class="fa-solid fa-circle-info"></i>
        </span>
      </div>
      <div class="col" *ngIf="checkPointTriggers?.length">
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="openTemplate(checkPointTriggerTempalte)"
        >
          Checkpoints
        </button>
      </div>
    </div>

    <div
      class="py-2 border-5 row"
      [ngClass]="
        promptName !== userPromptDetailedData?.name &&
        userPromptDetailedData?.id
          ? 'row-cols-3'
          : 'row-cols-2'
      "
    >
      <div class="col">
        <button
          type="button"
          class="btn btn-md btn-secondary w-100"
          (click)="previewPrompts()"
          [disabled]="!paginatedPromptData?.length"
        >
          Preview
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-md btn-primary w-100"
          (click)="savePrompts()"
          [disabled]="disabeSaveButton()"
        >
          {{ userPromptDetailedData?.id ? "Update" : "Save" }}
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-md"
          (click)="copyPrompt()"
          *ngIf="
            promptName !== userPromptDetailedData?.name &&
            userPromptDetailedData?.id
          "
          [disabled]="promptName === userPromptDetailedData?.name"
        >
          Save As
        </button>
      </div>
    </div>
    <div
      cdkDropList
      [cdkDropListData]="selectedPagePrompts"
      class="col-xs-12"
      (cdkDropListDropped)="dropSection($event)"
    >
      <div
        *ngFor="let field of selectedPagePrompts; let i = index"
        class="mb-3 card p-2"
        cdkDrag
        [cdkDragData]="field"
        [ngClass]="{ dragging: dragging }"
        (cdkDragStarted)="onDragStarted($event)"
        (cdkDragEnded)="onDragEnded($event)"
      >
        <div
          class="text-center cursor-move"
          cdkDragHandle
          *ngIf="selectedPagePrompts?.length > 1"
        >
          <span>
            <i class="fa-solid fa-grip"></i>
          </span>
        </div>
        <div>
          <ng-template
            *ngTemplateOutlet="
              formTemplate;
              context: {
                value: field,
                index: i,
                arrayList: selectedPagePrompts,
                mainSection: true
              }
            "
          ></ng-template>
        </div>
      </div>
    </div>

    <div class="row row-cols-2 mt-1 g-2">
      <div *ngFor="let opt of addPromptOptions">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="addItem(opt)"
        >
          {{ opt.promptLabel }}
        </button>
      </div>
    </div>
  </ng-container>

  <div class="row row-cols-3 mt-3">
    <div class="col">
      <button
        type="button"
        class="btn btn-lg btn-dark w-100"
        (click)="goBack()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-lg btn-secondary w-100"
        (click)="previewPrompts()"
        [disabled]="!paginatedPromptData?.length"
      >
        Preview
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-lg btn-primary w-100"
        (click)="savePrompts()"
        [disabled]="disabeSaveButton()"
      >
        {{ userPromptDetailedData?.id ? "Update" : "Save" }}
      </button>
    </div>
  </div>
</div>

<ng-template
  #formFieldOptionsTemplateRef
  let-formFieldOptionsTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Add Prompt
      <span
        class="float-end"
        (click)="formFieldOptionsTemplateRef.close('close')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="row row-cols-2 mt-1 g-2">
      <div *ngFor="let opt of addPromptOptions">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="
            addItem(opt, data?.option); formFieldOptionsTemplateRef.close()
          "
        >
          {{ opt.promptLabel }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  let-field="value"
  let-index="index"
  let-arrayList="arrayList"
  let-mainSection="mainSection"
  let-nestedKey="nestedKey"
  let-outerField="outerField"
  #formTemplate
>
  <div class="my-2">
    <div class="d-flex">
      <div class="flex-grow-1">
        <span *ngIf="mainSection" class="fw-bold"
          >SECTION {{ index + 1 }}:
        </span>
        <span *ngIf="!mainSection"
          >{{ outerField?.description }} > {{ nestedKey }}
        </span>
        <span *ngIf="mainSection">
          {{ field | misc : "promptLabel" : addPromptOptions }}
        </span>
      </div>
      <div>
        <span (click)="deleteSection(arrayList, index)">
          <i class="fa-regular fa-trash-can fa-2x"></i
        ></span>
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="field.type === 'output'">
      <div class="row row-cols-1 g-2">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="field.description"
            placeholder="Message*"
          />
          <label>Message*</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="field.type === 'input'">
      <div class="row row-cols-1 g-2">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="field.description"
            placeholder="Description / Question*"
          />
          <label>Description / Question*</label>
        </div>

        <ng-container *ngIf="field?.field === 'checkbox'">
          <div
            cdkDropList
            #currentList="cdkDropList"
            [cdkDropListData]="field.options"
            class="drag-drop-container drag-list mt-3 px-3"
            (cdkDropListDropped)="drop($event)"
            *ngIf="field.options?.length"
          >
            <div
              *ngFor="let opt of field.options; let i = index"
              class="mb-3 cursor-grab"
            >
              <div class="d-flex drag-box">
                <div class="pe-1" cdkDrag>
                  <span class="me-2">
                    <i class="fa-solid fa-bars"></i>
                  </span>
                </div>
                <div class="flex-grow-1">{{ opt?.key }}</div>

                <div
                  (click)="field?.options?.splice(i, 1)"
                  class="mx-3"
                  *ngIf="!field?.singleChoice"
                >
                  <i class="fa-regular fa-trash-can fa-2x"></i>
                </div>
                <!--nested feature-->
                <div
                  (click)="
                    openTemplate(formFieldOptionsTemplateRef, field, opt)
                  "
                >
                  <i class="fas fa-plus-circle fa-2x"></i>
                </div>
              </div>
              <!--nested feature structure-->
              <div>
                <ng-container *ngIf="opt?.selectSubOptions?.length">
                  <div
                    *ngFor="
                      let subField of opt?.selectSubOptions;
                      let subIndex = index
                    "
                    class="nested-prompt"
                  >
                    <ng-template
                      *ngTemplateOutlet="
                        formTemplate;
                        context: {
                          value: subField,
                          index: subIndex,
                          arrayList: opt?.selectSubOptions,
                          mainSection: false,
                          nestedKey: opt?.key,
                          outerField: field
                        }
                      "
                    ></ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="field?.singleChoice !== 1">
            <div class="d-flex">
              <div class="flex-grow-1">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Add Option"
                    [(ngModel)]="tempOption"
                  />
                  <label> Add Answer </label>
                </div>
              </div>
              <div class="ms-1">
                <button
                  type="button"
                  [disabled]="!tempOption"
                  class="btn btn-lg btn-secondary w-100 h-100"
                  (click)="
                    tempOption ? field.options.push({ key: tempOption }) : '';
                    tempOption = ''
                  "
                >
                  <i class="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <div class="form-floating">
              <input
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                type="tel"
                (input)="maxSelectionsAllowedValidation($event, field)"
                title="This determines the maximum number of options a user can select."
                class="form-control"
                [(ngModel)]="field.allowedSelections"
                placeholder="Answers Allowed "
              />
              <label>Answers Allowed</label>
            </div>
          </ng-container>
        </ng-container>

        <div class="form-floating" *ngIf="field?.field !== 'checkbox'">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="field.value"
            placeholder="Default Value (optional)"
          />
          <label>Default Value</label>
        </div>

        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="requiredField{{ index }}"
              [(ngModel)]="field.required"
            />
            <label for="requiredField{{ index }}">Required</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="field.showInReports"
              id="reportField{{ index }}"
            />
            <label for="reportField{{ index }}">
              Include in client reports</label
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template
  #checkPointTriggerTempalte
  let-checkPointTriggerTempalte="dialogRef"
>
  <div class="card dialog-box gt-p-small">
    <div class="card-header d-flex justify-content-between align-items-center">
      Checkpoint
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="checkPointTriggerTempalte.close()"
      ></button>
    </div>
    <div class="mt-2">
      <div
        *ngFor="let cp of checkPointTriggers"
        (click)="viewCheckpointDetail(cp); checkPointTriggerTempalte.close()"
        class="p-2 pointer"
      >
        <div class="table-card-data pointer">
          <div>
            <div *ngIf="cp?.qr_code" class="mb-0 pb-0 float-end">
              <img
                src="assets/images/qr-code-logo.png"
                alt=""
                width="26"
                *ngIf="cp?.qr_code"
                class="mb-0 pb-0"
              />
            </div>
            <span class="fw-bold">
              {{ cp?.name }}
            </span>
          </div>
          <div class="mt-2">
            {{ cp?.company_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
