<div
  [ngStyle]="{
    'padding-bottom': '80px'
  }"
>
  <ul class="nav nav-tabs">
    <li class="nav-item" (click)="selectedTab = tabTitles.tab1">
      <a
        class="nav-link"
        data-toggle="tab"
        [ngClass]="selectedTab === tabTitles.tab1 ? 'active' : ''"
        >{{ tabTitles.tab1 }}</a
      >
    </li>
    <li
      class="nav-item"
      (click)="selectedTab = tabTitles.tab2; showClockedInUsers = true"
    >
      <a
        class="nav-link"
        data-toggle="tab"
        [ngClass]="selectedTab === tabTitles.tab2 ? 'active' : ''"
        >{{ tabTitles.tab2 }}</a
      >
    </li>
  </ul>
  <div class="tab-content">
    <div
      role="tabpanel"
      class="tab-pane"
      id="tab1"
      [ngClass]="selectedTab === tabTitles.tab1 ? 'active' : ''"
      *ngIf="selectedTab === tabTitles.tab1"
    >
      <div class="my-2">
        <gtapp-auto-complete
          [data]="guardNbOptionList"
          placeHolder="Search Guards"
          searchKeyword="user_name"
          [initialValue]="guardNameValue"
          [itemTemplate]="guardTemplate"
          (inputChanged)="onGuardSearch($event)"
          (selected)="onGuardSelect($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #guardTemplate let-item>
        {{ item?.user_name ? item?.user_name : item?.email }}
      </ng-template>
      <div class="my-2" *ngIf="showMap === true">
        <gtapp-map-pins-view
          [listData]="guardLastLocationData"
          [mapFrom]="responsiveMapType()"
          [selectedGuardUser]="selectedGuardUser"
          (popupOpened)="selectGuard($event)"
        ></gtapp-map-pins-view>
      </div>
      <ng-container *ngIf="guardLatestInfoBackup?.length">
        <div class="row row-cols-auto my-3 gap-2">
          <div
            *ngFor="let key of guardStatusKeys; let i = index"
            class="d-flex align-items-center"
          >
            <input
              class="form-check-input mt-0"
              type="checkbox"
              [checked]="guardStatus?.[key]?.selected"
              (change)="filterGuardByStatus(key)"
              id="check-{{ key }}-{{ i }}"
            />
            <label class="form-check-label" for="check-{{ key }}-{{ i }}">
              <div [ngStyle]="guardStatus?.[key]?.circle" class="mx-2"></div>
              {{guardStatus?.[key]?.name}}
            </label>
          </div>
        </div>

        <table class="guard-info w-100" *ngIf="guardLatestInfo?.length">
          <tbody>
            <tr
              *ngFor="let guard of guardLatestInfo; let i = index"
              class="border-bottom pointer"
              (click)="selectGuard(guard)"
              [ngStyle]="
                selectedGuardIndex === i
                  ? {
                      'background-color': '#495057',

                      'cursor': 'pointer'
                    }
                  : {}
              "
            >
              <td class="pe-3 ps-2">
                <div [ngStyle]="guardStatus?.[guard?.status]?.circle"></div>
              </td>
              <td>
                {{ guard?.user_name }}
              </td>
              <td>
                <tbody *ngIf="guard?.shifts?.length">
                  <tr *ngFor="let shift of guard?.shifts">
                    <td>
                      {{ shift?.event_name }}
                      <span class="text-danger small">
                        [{{
                          shift?.schedule_details
                            | misc : "getScheduleTimeRange"
                        }}]
                      </span>
                    </td>
                  </tr>
                </tbody>
                <div
                  *ngIf="!guard?.shifts?.length"
                  class="fw-bold text-uppercase"
                >
                  No job assigned
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>

    <div
      role="tabpanel"
      class="tab-pane"
      id="tab2"
      [ngClass]="selectedTab === tabTitles.tab2 ? 'active' : ''"
      *ngIf="selectedTab === tabTitles.tab2"
    >
      <div *ngIf="showClockedInUsers" class="mt-4">
        <gtapp-card-list
          [mobileCardBackendData]="onlineUsers"
          [mobileCardDetails]="userOnlineEventDetails"
          (rowClick)="onUserSelection($event)"
        ></gtapp-card-list>
      </div>

      <div *ngIf="!showClockedInUsers" class="mt-4">
        <div class="form-floating">
          <input
            class="form-control input-font-sm"
            size="lg"
            placeholder="Pick Date Range "
            readonly
            [value]="dateRangeValue"
            (click)="openRangePicker()"
          />
          <label for="dateRange">Pick Date Range</label>
        </div>

        <div class="mt-4" *ngIf="selectedGuard?.id">
          <h5 class="text-center mb-1 mt-3">
            {{ selectedGuard?.full_name }}
          </h5>

          <div *ngIf="userEvents?.length == 0" class="my-3">
            <div
              class="alert alert-primary"
              role="alert"
              style="font-weight: 500"
            >
              No Records Match Your Search Criteria
            </div>
          </div>

          <div *ngIf="selectedGuardLocationInfo" class="mt-4">
            <h6 class="text-center mb-3">
              {{ selectedGuardLocationInfo?.event_type }} |
              {{
                selectedGuardLocationInfo?.updated_at
                  | customDate : "d MMM y HH:mm"
              }}
            </h6>

            <gtapp-map-leaflet
              *ngIf="mapLatLong"
              [latLon]="mapLatLong"
              [mapId]="responsiveMapId()"
            ></gtapp-map-leaflet>

            <div class="text-center mt-2">
              <button
                (click)="prevLocation()"
                class="btn btn-secondary"
                [disabled]="selectedGuardLocationInfo?.index === 1"
              >
                Newer
              </button>

              <span>
                {{ selectedGuardLocationInfo?.index }} of
                {{ totalRowsUserLocationHistory }}
              </span>

              <button
                (click)="nextLocation()"
                class="btn btn-secondary"
                [disabled]="
                  selectedGuardLocationInfo?.index >=
                  totalRowsUserLocationHistory
                "
              >
                Older
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3 bottom-section" id="fixedBtn">
  <div class="px-2 pb-2">
    <button
      class="btn btn-dark d-block d-md-none w-100 btn-lg"
      (click)="goBackButton()"
    >
      Back
    </button>
  </div>
</div>
