import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formatDateTimeStamp,
  getInitalDateRangeForListData,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import { getDateTimeRange } from '../tableDetails';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-missed-events',
  templateUrl: './missed-events.component.html',
  styleUrl: './missed-events.component.scss',
})
export class MissedEventsComponent {
  //missed welfare check data variables
  totalRowsMissedWelfareCheck: number = 0;
  previousMissedWelfareCheck: number = 0;
  pageSizeMissedWelfareCheck: number = 10;
  pageNumMissedWelfareCheck: number = 1;
  totalPagesMissedWelfareCheck: number = 0;
  searchParamsMissedWelfareCheck: any;
  sortKeysMissedWelfareCheck: any = ['-missed_at'];
  dateRangeMissedWelfareCheck: any;

  otherParamsMissedWelfareCheck: any = {};
  missedWelfareData: any;
  tableDetailMissedWelfareCheck = [
    {
      header: 'Event',
      name: 'event_key',
    },
    {
      header: 'Time Range',
      nestedValue: (row: any) => {
        return `${row?.start_time} - ${row?.end_time}`;
      },
    },
    {
      header: 'Missed At',
      name: 'missed_at',
      datetimeObj: true,
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
      ...getDateTimeRange(),
    },

    {
      header: 'Last Activity At',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(),
    },
    {
      header: 'Last Activity',
      name: 'action',
    },
    {
      header: 'Guard',
      name: 'full_name',
      searchKey: 'full_name',
      sortKey: 'full_name',
    },
  ];
  mobileDetailMissedWelfareCheck = {
    individualDetails: [
      {
        name: 'full_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
          'font-weight': 'bold',
        },
      },
      {
        prefix: 'Missed At: ',
        name: 'missed_at',
        dateRangeKey: 'missed_at',
        ...getDateTimeRange(),
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'margin-top': '0.5rem',
          'font-weight': 'bold',
        },
      },
      {
        prefix: 'Missed Reason: ',
        name: 'missed_reason',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        prefix: 'Last Activity : ',
        nestedValue: (row: any) => {
          return `${row?.action} | ${String(
            formatDateTimeStamp(row?.updated_at, 'd MMM y HH:mm', 'en_US')
          )}`;
        },
        header: 'Last Activity',
        dateRangeKey: 'updated_at',
        ...getDateTimeRange(),
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  //missed checkpoint data variables
  totalRowsMissedCheckpoint: number = 0;
  previousMissedCheckpoint: number = 0;
  pageSizeMissedCheckpoint: number = 10;
  pageNumMissedCheckpoint: number = 1;
  totalPagesMissedCheckpoint: number = 0;
  searchParamsMissedCheckpoint: any;
  sortKeysMissedCheckpoint: any = [];
  dateRangeMissedCheckpoint: any;

  otherParamsMissedCheckpoint: any = {};
  missedCheckpointData: any;
  tableDetailMissedCheckpoint = [
    {
      header: 'Checkpoint',
      name: 'checkpoint_name',
      searchKey: 'check_point__name',
      sortKey: 'check_point__name',
    },
    {
      header: 'Address',
      name: 'checkpoint_address',
      searchKey: 'full_address',
      sortKey: 'full_address',
    },
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'check_point__company__company_name',
      sortKey: 'check_point__company__company_name',
    },
    {
      header: 'Patrol Route',
      name: 'patrol_route_name',
      searchKey: 'patrol_route__name',
      sortKey: 'patrol_route__name',
    },
    {
      header: 'Guards',
      nestedValue: (row: any) => {
        return row?.guards?.join(', ');
      },
      searchKey: 'guards',
    },

    {
      header: 'Date',
      name: 'missed_at',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
      ...getDateTimeRange(),
    },
    {
      header: 'Time Range',
      name: 'time_range',
    },
  ];

  mobileDetailMissedCheckpoint = {
    individualDetails: [
      {
        name: 'checkpoint_name',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 600,
        },
      },
      {
        name: 'checkpoint_address',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-weight': 600,
        },
      },
      {
        prefix: 'Patrol Route : ',
        name: 'patrol_route_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        prefix: 'Guards : ',
        nestedValue: (row: any) => {
          return row?.guards?.join(', ');
        },
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'time_range',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'missed_at',
        dateTimeFormat: 'd MMM y',
        datetimeObj: true,
        dateRangeKey: 'missed_at',
        ...getDateTimeRange(),
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  dialogeRef: any;
  defaultTimeRange: any;
  selectedMissedWelfare: any;
  tabTitles = {
    tab1: 'Welfare Checks',
    tab2: 'Missed Scans',
  };
  selectedTab: string = this.tabTitles.tab1;
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  constructor(
    private userService: UsersService,
    private checkpointService: CheckpointService,
    private router: Router,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {}

  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    const today = new Date();
    today.setDate(new Date().getDate() - 1);

    this.defaultTimeRange = {
      start: today,
      end: new Date(),
    };
    this.dateRangeMissedCheckpoint = getInitalDateRangeForListData(
      this.mobileDetailMissedCheckpoint?.individualDetails || []
    );
    this.dateRangeMissedWelfareCheck = getInitalDateRangeForListData(
      this.mobileDetailMissedWelfareCheck?.individualDetails || []
    );

    this.getMissedWelfareCheckData();
    this.getMissedCheckpointData();
  }
  getMissedWelfareCheckData() {
    let body: any = {
      event_type: 'missed_welfare_checks',
    };
    if (this.dateRangeMissedWelfareCheck)
      body.date_range = this.dateRangeMissedWelfareCheck;

    if (this.sortKeysMissedWelfareCheck)
      body.sort_key = this.sortKeysMissedWelfareCheck;

    if (this.searchParamsMissedWelfareCheck)
      body.search_filters = this.searchParamsMissedWelfareCheck;

    if (this.pageSizeMissedWelfareCheck) {
      body['rows'] = this.pageSizeMissedWelfareCheck;
    }
    if (this.previousMissedWelfareCheck) {
      body['previous'] = this.previousMissedWelfareCheck;
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.missedWelfareData = response['data'];

        this.totalRowsMissedWelfareCheck = response['total_size'];

        this.totalPagesMissedWelfareCheck = Math.ceil(
          this.totalRowsMissedWelfareCheck / this.pageSizeMissedWelfareCheck
        );
        this.otherParamsMissedWelfareCheck = {
          paginationData: {
            totalRows: this.totalRowsMissedWelfareCheck,
            previousRows: this.previousMissedWelfareCheck,
            rows: this.pageSizeMissedWelfareCheck,
            pageNum: this.pageNumMissedWelfareCheck,
            totalPages: this.totalPagesMissedWelfareCheck,
          },
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  onChangePaginationMissedWelfareCheck(data: any) {
    this.previousMissedWelfareCheck = data?.paginationData?.previousRows;
    this.pageNumMissedWelfareCheck = data?.paginationData?.pageNum;
    this.pageSizeMissedWelfareCheck = data?.paginationData?.rows;

    this.getMissedWelfareCheckData();

    this.otherParamsMissedWelfareCheck.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedWelfareCheck(sortData: any) {
    this.resetPagenMissedWelfareCheck();
    this.sortKeysMissedWelfareCheck = sortData?.sortList || [];
    this.getMissedWelfareCheckData();
  }

  searchColumnMissedWelfareCheck(searchData: any) {
    this.resetPagenMissedWelfareCheck();
    this.dateRangeMissedWelfareCheck = null;
    this.searchParamsMissedWelfareCheck = searchData?.searchParams || {};

    this.getMissedWelfareCheckData();
  }
  resetPagenMissedWelfareCheck() {
    this.totalRowsMissedWelfareCheck = 0;
    this.previousMissedWelfareCheck = 0;
    this.pageSizeMissedWelfareCheck = 10;
    this.pageNumMissedWelfareCheck = 1;
    this.totalPagesMissedWelfareCheck = 0;
    this.otherParamsMissedWelfareCheck.paginationData = {
      totalRows: this.totalRowsMissedWelfareCheck,
      previousRows: this.previousMissedWelfareCheck,
      rows: this.pageSizeMissedWelfareCheck,
      pageNum: this.pageNumMissedWelfareCheck,
      totalPages: this.totalPagesMissedWelfareCheck,
    };
  }
  getMissedCheckpointData() {
    let body: any = {};
    if (this.dateRangeMissedCheckpoint)
      body.date_range = this.dateRangeMissedCheckpoint;

    if (this.sortKeysMissedCheckpoint)
      body.sort_key = this.sortKeysMissedCheckpoint;
    if (this.searchParamsMissedCheckpoint)
      body.search_filters = this.searchParamsMissedCheckpoint;

    if (this.pageSizeMissedCheckpoint) {
      body['rows'] = this.pageSizeMissedCheckpoint;
    }
    if (this.previousMissedCheckpoint) {
      body['previous'] = this.previousMissedCheckpoint;
    }

    this.checkpointService
      .missedCheckpoints(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.missedCheckpointData = response['data'];

          this.totalRowsMissedCheckpoint = response['total_size'];

          this.totalPagesMissedCheckpoint = Math.ceil(
            this.totalRowsMissedCheckpoint / this.pageSizeMissedCheckpoint
          );
          this.otherParamsMissedCheckpoint = {
            paginationData: {
              totalRows: this.totalRowsMissedCheckpoint,
              previousRows: this.previousMissedCheckpoint,
              rows: this.pageSizeMissedCheckpoint,
              pageNum: this.pageNumMissedCheckpoint,
              totalPages: this.totalPagesMissedCheckpoint,
            },
          };
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  onChangePaginationMissedCheckpoint(data: any) {
    this.previousMissedCheckpoint = data?.paginationData?.previousRows;
    this.pageNumMissedCheckpoint = data?.paginationData?.pageNum;
    this.pageSizeMissedCheckpoint = data?.paginationData?.rows;

    this.getMissedCheckpointData();

    this.otherParamsMissedCheckpoint.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedCheckpoint(sortData: any) {
    this.resetPageMissedCheckPoint();
    this.sortKeysMissedCheckpoint = sortData?.sortList || [];
    this.getMissedCheckpointData();
  }
  searchColumnMissedCheckpoint(searchData: any) {
    this.resetPageMissedCheckPoint();
    this.dateRangeMissedCheckpoint = null;
    this.searchParamsMissedCheckpoint = searchData?.searchParams || {};
    this.getMissedCheckpointData();
  }
  resetPageMissedCheckPoint() {
    this.totalRowsMissedCheckpoint = 0;
    this.previousMissedCheckpoint = 0;
    this.pageSizeMissedCheckpoint = 10;
    this.pageNumMissedCheckpoint = 1;
    this.totalPagesMissedCheckpoint = 0;
    this.otherParamsMissedCheckpoint.paginationData = {
      totalRows: this.totalRowsMissedCheckpoint,
      previousRows: this.previousMissedCheckpoint,
      rows: this.pageSizeMissedCheckpoint,
      pageNum: this.pageNumMissedCheckpoint,
      totalPages: this.totalPagesMissedCheckpoint,
    };
  }
}
