<ul class="nav nav-tabs">
  <li class="nav-item" (click)="changeTab(tabsUsers.tab1)">
    <a
      class="nav-link"
      [ngClass]="selectedTab === tabsUsers.tab1 ? 'active' : ''"
      >{{ tabsUsers.tab1 }}</a
    >
  </li>
  <li class="nav-item" (click)="changeTab(tabsUsers.tab2)">
    <a
      class="nav-link"
      [ngClass]="selectedTab === tabsUsers.tab2 ? 'active' : ''"
      >{{ tabsUsers.tab2 }}</a
    >
  </li>
</ul>

<div class="mt-3">
  <div class="card-body">
    <!-- MOBILE VIEW -->

    <div class="tab-content">
      <div
        class="tab-pane"
        [ngClass]="selectedTab === tabsUsers.tab1 ? 'active' : ''"
      >
        <div class="mt-3 text-center fw-bold mb-2" *ngIf="!isTestAccount">
          <span>Total Guards</span>
          <span>
            {{ totalAddedUsers }}
            <span *ngIf="allotedUsers"> / {{ allotedUsers }}</span>
          </span>
        </div>
        <div class="alert alert-primary text-center recommendation-alert">
          We recommend switching your plan to
          <span class="fw-bold">{{ recommendedPlan }}</span> for a better rate
          <div class="mt-2">
            <button class="btn btn-info" (click)="changePlan()">
              See Plans
            </button>
          </div>
        </div>
        <div class="d-flex flex-column flex-sm-row input-btn-group">
          <div class="flex-sm-grow-1 mb-2 mb-sm-0">
            <gtapp-auto-complete
              [data]="userDropDownList"
              placeHolder="Search Users"
              searchKeyword="full_name"
              [itemTemplate]="userTemplate"
              (inputChanged)="onUserLookup($event)"
              (selected)="rowClick($event)"
              (submitted)="
                globalFilter = $event; userPaginationData = {}; getUsers()
              "
            >
            </gtapp-auto-complete>

            <ng-template #userTemplate let-item>
              <div>{{ item?.full_name }}</div>
            </ng-template>
          </div>
          <div class="ms-sm-2">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="openAddUserForm()"
            >
              + Add User
            </button>
          </div>
        </div>

        @if (!largeView) {
        <div>
          <gtapp-card-list
            [mobileCardBackendData]="userPaginationData[pageNum]"
            [mobileCardDetails]="userCardDetails"
            [otherParams]="otherParams"
            (sortColumn)="sortColumn($event)"
            (searchColumn)="searchColumn($event)"
            (rowClick)="rowClick($event)"
            (paginationChange)="onChangePagination($event)"
          ></gtapp-card-list>
        </div>
        }@else {
        <!-- DESKTOP VIEW -->
        <div>
          <gtapp-gt-table
            [desktopTableBackendData]="userPaginationData[pageNum]"
            [desktopTableDetails]="userDetailsTableView"
            [tableStyle]="tableStyle"
            [otherParams]="otherParams"
            (sortColumn)="sortColumn($event)"
            (searchColumn)="searchColumn($event)"
            (rowClick)="rowClick($event)"
            (paginationChange)="onChangePagination($event)"
          ></gtapp-gt-table>
        </div>
        }
      </div>
    </div>
  </div>

  <div *ngIf="totalUserCount < 1">
    <div class="alert alert-danger" role="alert">No Users Found</div>
  </div>
</div>
<div class="tab-content">
  <div
    class="tab-pane"
    [ngClass]="selectedTab === this.tabsUsers.tab2 ? 'active' : ''"
  >
    <gtapp-user-group
      *ngIf="selectedTab === this.tabsUsers.tab2"
    ></gtapp-user-group>
  </div>
</div>
