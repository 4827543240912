import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isLargeScreen } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { PwaInstallationGuideComponent } from '../../../shared/components/pwa-installation-guide/pwa-installation-guide.component';
import { QrCodeComponent } from '../../../shared/components/qr-code/qr-code.component';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { JobsService } from '../jobs/jobs.service';
import { PagesService } from '../pages.service';
import { UserPromptService } from '../user-prompts/user-prompt.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, AfterViewInit {
  updateTask: any = 'updateJob';
  userData: any;
  staleDays: any;
  dashBoardData: any;
  subscriberStats: any;
  jobStats: any;
  userStats: any;
  isDispatchUser: any;

  isAdmin: any;
  offlineMode: boolean = false;
  isSuperUser: boolean = false;
  dialogRef: any;
  messageChannel = new MessageChannel();
  offlineData: any;
  isGuardUser: boolean;
  isGuardClockedIn: boolean;
  deferredPrompt: any;
  isAppInstalled: boolean = false;
  isSubscriberAdmin: boolean = false;
  isSubscriptionActive: boolean = false;
  bottomSectionFixedHeight: string = '180px';
  isPageLoad: boolean = false;
  serviceWorkerEnabled: boolean = false;
  systemSettings: any = {};
  largeView = isLargeScreen;

  userPrompts: any = [];
  updateEvenDetails: any = {};
  currentDevice: import('ngx-device-detector').DeviceInfo;
  isSOSButtonPressed: boolean = false;

  timer: any;
  pressStartTime: any;
  pressDurationThreshold: number = 3000; //  3 second
  cameraOnDevice: boolean = true;

  //missed welfare check data variables
  totalRowsMissedWelfareCheck: number = 0;
  previousMissedWelfareCheck: number = 0;
  pageSizeMissedWelfareCheck: number = 10;
  pageNumMissedWelfareCheck: number = 1;
  totalPagesMissedWelfareCheck: number = 0;
  searchParamsMissedWelfareCheck: any;
  sortKeysMissedWelfareCheck: any = ['-missed_at'];
  dateRangeMissedWelfareCheck: any;
  dateRangeValueMissedWelfareCheck: any;

  otherParamsMissedWelfareCheck: any;
  missedWelfareData: any;
  constructor(
    private appService: AppService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private jobService: JobsService,
    private deviceService: DeviceDetectorService,
    private ngZone: NgZone,
    private swUpdate: SwUpdate,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService,
    private pageService: PagesService,
    private viewContainerRef: ViewContainerRef,
    private userPromptService: UserPromptService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private userService: UsersService
  ) {
    this.serviceWorkerEnabled = this.swUpdate.isEnabled;
    this.userData = this.appService.getUserData();
    this.offlineMode = !navigator?.onLine;
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.currentDevice = this.deviceService.getDeviceInfo();
    if (!this.userData?.access) {
      this.router.navigate(['/login']);
    }
    if (this.isSuperUser === true) {
      this.router.navigate(['/gtadmin/dashboard']);
    }
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isGuardUser = this.dataCheckService.isGuardUser();
    this.isGuardClockedIn = this.dataCheckService.isGuardUserClockendIn();
    this.isSubscriberAdmin = this.dataCheckService.isSubscriberAdmin();
    this.isSubscriptionActive =
      this.dataCheckService.checkIsSubscriptionOrTestUser();
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // to trigger the installation prompt
    });
  }

  isComingFromOnlineCheck() {
    this.offlineDataCheck();

    this.offlineMode = !navigator?.onLine;
  }

  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.cdr.detectChanges();
        this.spinnerService.hide();
      });
    }, 100);
  }
  ngOnInit() {
    this.spinnerService.show();

    if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
      this.router.navigate([
        '/subscription',
        {
          state: 'Account Verified! Subscription Required',
        },
      ]);
    } else {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (!isIOS) {
        window.addEventListener('appinstalled', (event) => {
          // PWA app is installed
          this.isAppInstalled = true;
        });
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.isAppInstalled = true;
      }
      window.addEventListener(
        'online',
        this.isComingFromOnlineCheck.bind(this)
      );
      window.addEventListener(
        'offline',
        this.isComingFromOnlineCheck.bind(this)
      );
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        // Stash the event so it can be triggered later
        this.deferredPrompt = event;
        let device = this.deviceService.getDeviceInfo();
        if (
          window.matchMedia('(display-mode: standalone)').matches ||
          (device.device !== 'iPhone' && !this.deferredPrompt)
        ) {
          this.isAppInstalled = true;
        }
      });

      this.router.navigate(['/']);
      if (!this.isSuperUser) {
        this.getHomePageData();
        this.getHomeScreenPrompts();
      }

      this.offlineDataCheck();
      // this.serviceWorkerOperations();
      this.spinnerService.hide();
    }
    this.ngZone.run(() => {
      setTimeout(() => {
        this.isPageLoad = true;
        this.calculateFixedBottomHeight();
      }, 300);
    });
    if (
      this.updateTask === 'updateJob' &&
      sessionStorage.getItem('updateAvailable') === 'true' &&
      this.swUpdate?.isEnabled
    ) {
      //making sure that the user is in home page and is doing nothing, so that we can trigger the swupdate
      sessionStorage.removeItem('updateAvailable');

      this.swUpdate?.activateUpdate().then(() => {
        this.ngZone.run(() => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      });
    }
  }
  offlineDataCheck() {
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'offlineDataCheck',
    });

    // listening for messages from service worker
    navigator?.serviceWorker?.addEventListener('message', (event) => {
      if (event?.data?.type === 'offlineDataCheck') {
        this.formatOfflineSavedData(event?.data);
      }
    });
  }

  closeDetailedHome(event: any) {
    if (
      [
        'scanCheckPoint',
        'addClientComment',
        'addClientPhoto',
        'userPromptInfo',
      ].includes(this.updateTask)
    ) {
      this.updateTask = 'updateJob';
    }
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'offlineDataCheck',
    });
  }

  getHomePageData() {
    this.spinnerService.show();
    let param: any = { time_offset: new Date().getTimezoneOffset() };
    if (this.staleDays) {
      param.stale_days = this.staleDays;
    }

    this.jobService.getDashBoardData(param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.dashBoardData = response['data'];

        this.formatDashBoardData(this.dashBoardData);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  formatDashBoardData(data: any) {
    //As route feat is not added at the moment no need to call this api.
    // this.getPendingCheckPoints();
    this.subscriberStats = data.subscriber_stats;

    localStorage.setItem(
      'subscriberStats',
      JSON.stringify(data.subscriber_stats)
    );
    this.jobStats = data?.job_stats;

    this.userStats = data?.user_stats;
    this.isPageLoad = true;
    this.spinnerService.hide();
  }
  redirectToPage(string: any) {
    if (string === 'addReportUsers') {
      this.router.navigate(['/client'], {
        fragment: 'addReportUsers',
      });
    } else if (string == 'patrolHistory') {
      this.router.navigate(['/user-events']);
    } else if (string == 'eventLog') {
      this.router.navigate(['/user-events'], { fragment: 'eventLog' });
    } else if (string == 'myJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'jobs',
      });
    } else if (string == 'unassignedJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'unassignedJobs',
      });
    } else if (string == 'staleJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'staleJobs',
      });
    } else if (string == 'guardLocations') {
      this.router.navigate(['/guards']);
    } else if (string == 'validateQr') {
      this.router.navigate(['/checkpoints'], {
        fragment: 'validateQr',
      });
    } else if (string == 'incidents') {
      this.router.navigate(['/incidents'], {});
    }
  }

  formatOfflineSavedData(data: any) {
    this.offlineData = data;

    this.offlineData.savedData = this.offlineData?.savedData?.filter(
      (savedItem: any) => !savedItem?.apiCallSuccess && !savedItem?.retry_count
    );
  }

  installPWAApp() {
    this.spinnerService.show();
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      this.dialogRef = this.dialogService.open(PwaInstallationGuideComponent, {
        data: {},
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {});

      this.spinnerService.hide();
    } else {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.spinnerService.hide();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            this.isAppInstalled = true;
            localStorage.setItem('isAppInstalled', 'true');
          }

          // Clear the deferredPrompt variable
          this.deferredPrompt = null;

          // Hide the install button after installation
        });
      } else {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: 'ERROR: Guard Tracker may already be installed',
        });
      }
    }
  }
  openQueuedTemplate(template: TemplateRef<any>) {
    this.dialogService.open(template, {}, this.viewContainerRef);
  }

  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID
    const bottomSectionFixed = document.getElementById('fixedBtn');
    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() === '0'
          ? '180' + 'px'
          : bottomSectionFixed.offsetHeight.toString() + 'px';
      this.cdr.detectChanges();
    }
  }
  async openQRCode() {
    if (await this.hasCamera()) {
      if ((await this.checkCameraPermission()) === true) {
        this.appService.permissionErrorAlert.next('cameraDisabled');
      } else {
        this.dialogRef = this.dialogService.open(QrCodeComponent, {
          data: {
            showFrom: 'home',
            cachedQRCheckpoints: this.offlineData?.qrCheckpointData?.data,
          },
        });
        this.dialogRef?.afterClosed()?.subscribe((value: any) => {
          this.offlineDataCheck();
        });
      }
    } else {
      this.cameraOnDevice = false;
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Unable to access camera',
      });
    }
  }
  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }

  handleSOSPress(
    isPressed: boolean,
    alertSOSTemplateRef: TemplateRef<any>,
    confirmSOSTemplate: TemplateRef<any>
  ) {
    this.isSOSButtonPressed = isPressed;
    if (isPressed) {
      this.pressStartTime = Date.now();
      this.timer = setTimeout(() => {
        this.callSOSApi(confirmSOSTemplate);
        this.isSOSButtonPressed = false;
      }, this.pressDurationThreshold);
    } else {
      clearTimeout(this.timer);
      const pressDuration = Date.now() - this.pressStartTime;
      if (pressDuration < this.pressDurationThreshold) {
        this.dialogRef = this.dialogService.open(
          alertSOSTemplateRef,
          {
            data: {},
          },
          this.viewContainerRef
        );
      }
      this.isSOSButtonPressed = false;
    }
  }

  callSOSApi(alertSOSTemplateRef: TemplateRef<any>) {
    this.spinnerService.show();
    this.profileService
      .updateUser({}, { send_sos: 1 })
      .then((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.dialogRef = this.dialogService.open(
            alertSOSTemplateRef,
            {
              data: {},
            },
            this.viewContainerRef
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
      });
  }
  async checkCameraPermission() {
    try {
      if (!navigator.permissions) {
        // Permissions API not supported
        return false;
      }

      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      return Boolean(
        !['granted', 'prompt'].includes(permission.state) &&
          this.currentDevice.device !== 'iPhone'
      );
    } catch (error) {
      console.log('Error checking camera permission:', error);
      return false;
    }
  }
  hasCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        resolve(false);
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoInputDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
          resolve(videoInputDevices.length > 0);
        })
        .catch((err) => {
          console.error('Error accessing media devices.', err);
          resolve(false);
        });
    });
  }
  openMissedWelfareCheckInEvents(template?: TemplateRef<any>) {
    this.spinnerService.show();

    let body: any = {
      event_type: 'missed_welfare_checks',
      missed: 1,
    };
    if (this.dateRangeMissedWelfareCheck && window.innerWidth < 770)
      body.date_range = this.dateRangeMissedWelfareCheck;

    if (this.sortKeysMissedWelfareCheck)
      body.sort_key = this.sortKeysMissedWelfareCheck;

    if (this.searchParamsMissedWelfareCheck)
      body.search_filters = this.searchParamsMissedWelfareCheck;

    if (this.pageSizeMissedWelfareCheck) {
      body['rows'] = this.pageSizeMissedWelfareCheck;
    }
    if (this.previousMissedWelfareCheck) {
      body['previous'] = this.previousMissedWelfareCheck;
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.missedWelfareData = response['data'];

        if (this.missedWelfareData?.length) {
          if (template)
            this.dialogRef = this.dialogService.open(
              template,
              {
                data: {},
              },
              this.viewContainerRef
            );
        } else {
          this.subscriberStats.missed_welfare_checks = 0;
        }

        this.spinnerService.hide();
        this.totalRowsMissedWelfareCheck = response['total_size'];

        this.totalPagesMissedWelfareCheck = Math.ceil(
          this.totalRowsMissedWelfareCheck / this.pageSizeMissedWelfareCheck
        );
        this.otherParamsMissedWelfareCheck = {
          paginationData: {
            tableTotalRows: this.totalRowsMissedWelfareCheck,
            tablePreviousRows: this.previousMissedWelfareCheck,
            tableRows: this.pageSizeMissedWelfareCheck,
            tablePageNum: this.pageNumMissedWelfareCheck,
            tableTotalPages: this.totalPagesMissedWelfareCheck,
          },
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  updateMissedReason(missedEventData: any) {
    this.jobService
      .updateMissedEvent({
        event_id: missedEventData?.id,
        missed_reason: missedEventData?.missedReason,
      })
      .subscribe((response: any) => {
        if (response?.status === 'success') {
          this.openMissedWelfareCheckInEvents();
        }
      });
  }
  textError() {
    throw new Error('Test the Error!');
  }
}
