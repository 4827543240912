import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  convertTime12to24,
  getFormattedDate,
  getFormattedTime,
  isLargeScreen,
} from '../../../../../../global.variable';
import { AuthService } from '../../../../../auth/auth.service';
import { AddEditLicenseDetailsComponent } from '../../../../../shared/components/add-edit-license-details/add-edit-license-details.component';
import { AddEditSiteComponent } from '../../../../../shared/components/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../../../shared/components/contact/contact.component';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { ProfileService } from '../../../../profile/profile.service';
import { ClientsService } from '../../../clients/clients.service';
import { JobsService } from '../../jobs.service';
type autoDropdownKey =
  | 'clientSelected'
  | 'siteSelected'
  | 'assigneeSelected'
  | 'onsiteSelected';
@Component({
  selector: 'gtapp-other',
  templateUrl: './other.component.html',
  styleUrl: './other.component.scss',
})
export class OtherComponent implements OnInit {
  @Input() selectedStatus: any;
  @ViewChild('licenceCheckTemplateRef') licenceCheckTemplateRef: any;
  formdata = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    siteName: new UntypedFormControl('', Validators.required),
    siteContactName: new UntypedFormControl(''),
    assigneeName: new UntypedFormControl(),
    requestDetail: new UntypedFormControl(),
  });

  isAdmin: boolean = this.dataCheckService.isUserAdmin();

  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;

  addJobPressed: boolean = false;
  scheduleStepperActive: boolean = false;

  otherJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    assignee: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    ai: {
      name: 'Additional Information',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    oc: {
      name: 'On Site Contact',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    schedule: {
      name: 'Schedule',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  siteList: any = [];
  siteContacts: any = [];
  assigneeList: any = [];

  companyLicenseList: any = [];

  dialogRef: any;
  userData: any;

  intervalData = [
    { name: 'No Welfare Checks', value: 0 },
    { name: 'Welfare Checks Every 5 mins', value: 5 },
    { name: 'Welfare Checks Every 15 mins', value: 15 },
    { name: 'Welfare Checks Every 30 mins', value: 30 },
  ];
  intervalCheck: any;
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),

    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  addSchedule: boolean = false;
  currentStep: number = 1;

  largeView = isLargeScreen;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private jobService: JobsService,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private customerService: ClientsService,
    private authService: AuthService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService
  ) {
    this.userData = this.authService.getUserData();

    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }
  ngOnDestroy() {
    sessionStorage.removeItem('schedulerData');
  }

  ngOnInit(): void {
    this.otherJobType.jobType.value = this.selectedStatus?.name;
    this.otherJobType.jobType.data = this.selectedStatus;

    this.getAssignees();
  }
  clearValues(_field: string) {
    if (_field === 'client') {
      this.otherJobType.client = {
        ...this.otherJobType.client,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.site = {
        ...this.otherJobType.site,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    } else if (_field == 'site') {
      this.otherJobType.site = {
        ...this.otherJobType.site,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    } else if (_field == 'oc') {
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
  }

  onSiteSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('site');
    this.otherJobType.site.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.customerService
        .getSites({
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res?.data || [];
          }
        });
    }
  }
  doSiteLicenseCheck() {
    if (
      !this.companyLicenseList.some(
        (i: any) => i === this.otherJobType.site?.data?.address?.state_code
      )
    ) {
      this.dialogRef = this.dialogService.open(this.licenceCheckTemplateRef, {
        data: {},
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {});
    }
  }
  ignoreLicenseCheck() {
    this.siteLicenceReminderCheck = false;

    if (this.licenseReminderField === true) {
      let params = { stop_notification: 1 };
      this.customerService
        .updateCompanyDetails({}, params)
        .then((response: any) => {
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
          this.userData['checks']['license_reminder'] = false;
          this.authService.setUserData(this.userData);
        });
    }
  }
  async onSelectSite(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.clearValues('oc');

      this.otherJobType.site.value = data?.company_name;
      this.otherJobType.site.data = data;
      this.formdata.controls['siteName'].setValue(data?.company_name);
      if (data?.company) {
        this.otherJobType.client.value = data?.company?.company_name;
        this.otherJobType.client.data = data?.company;
        this.formdata.controls['clientName'].setValue(data?.company_name);
      }
      this.getSiteContacts(data.id);

      this.assigneeList = await this.getGuardDetails(this.assigneeList);
      if (this.siteLicenceReminderCheck && this.isAdmin == true) {
        this.doSiteLicenseCheck();
      }
    }
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: { company: this.otherJobType.client.data },
        siteAddress: this.otherJobType.site.tempValue,
        showFrom: 'addJob',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectSite(value);
      }
    });
  }
  getSiteContacts(id: any) {
    this.customerService.getSiteContacts(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.siteContacts = response['data'];
      } else {
        this.toasterService.setMessage({
          errorMessage: 'ERROR MESSAGE',
          successMessage: '',
        });
      }
    });
  }
  selectContact(contact: any) {
    if (contact?.key === 0) {
      this.addNewContactForm();
    }
    if (contact?.id) {
      this.otherJobType.oc.value = contact?.full_name;
      this.otherJobType.oc.data = contact;
      this.formdata.controls['siteContactName'].setValue(contact?.full_name);
      this.currentStep = 3;
    }
  }
  addNewContactForm() {
    if (this.otherJobType.site.data) {
      let siteData: any = this.otherJobType.site.data;
      siteData['contacts'] = this.siteContacts;
      this.dialogRef = this.dialogService.open(ContactComponent, {
        data: {
          siteData: siteData,
          addNewSiteContact: true,
          contactType: 'lc',
        },
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {
        if (value !== 'false') {
          if (value && value?.length) {
            this.siteContacts = value;

            let lastAddedContact =
              this.siteContacts[this.siteContacts?.length - 1];
            if (lastAddedContact) this.selectContact(lastAddedContact);
          }
        }
      });
    }
  }
  validLicenseCheck(guardLicenses: any) {
    return guardLicenses.some(
      (license: any) =>
        license.issuer_state ===
        this.otherJobType.site?.data?.address?.state_code
    );
  }
  async getGuardDetails(guardsData: any) {
    return new Promise((resolve) => {
      guardsData?.forEach((guard: any) => {
        guard['validStateLicense'] = this.validLicenseCheck(guard?.license);
      });

      resolve(guardsData);
    });
  }
  onAssigneeSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.getAssignees({ full_name: event?.target?.value });
    }
  }
  getAssignees(params?: any) {
    this.jobService.getAssignees(params).subscribe(async (response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = await this.getGuardDetails(response['data']);
      }
    });
  }
  onAssigneeSelect(assignee: any) {
    if (assignee?.id) {
      this.otherJobType.assignee.value = assignee?.full_name;
      this.otherJobType.assignee.data = assignee;
      this.formdata.controls['assigneeName'].setValue(assignee?.full_name);
      if (assignee?.validStateLicense) {
        this.currentStep = 4;
      } else {
        let dialogMsg;
        if (this.userData.profile.email === assignee.email) {
          dialogMsg =
            'You have not added a security licence for this location on your profile, are you sure you want to take this job?';
        } else {
          dialogMsg = `${
            assignee.full_name || assignee.email
          } does not hold a security licence for this location, assign to ${
            assignee.full_name || assignee.email
          } anyway?`;
        }
        this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Job Assignment',
            message: dialogMsg,
          },
        });
        this.dialogRef.afterClosed().subscribe((value: any) => {
          if (value === true) {
            this.otherJobType.assignee.value = assignee?.full_name;
            this.otherJobType.assignee.data = assignee;
            this.formdata.controls['assigneeName'].setValue(
              assignee?.full_name
            );
            this.currentStep = 4;
          } else {
            this.otherJobType.assignee.value = '';
            this.otherJobType.assignee.data = null;
            this.formdata.controls['assigneeName'].setValue(null);
          }
        });
      }
    }
  }
  onAddJob() {
    this.addJobPressed = true;
    this.spinnerService.show();
    let data: any = {
      job_type_id: this.selectedStatus.id,
      company_id: this.otherJobType?.client?.data?.id,
      location: [this.otherJobType?.site?.data?.id],
      is_abandoned: false,
      request: this.formdata.value.requestDetail,
      new_assignee: this.otherJobType?.assignee?.data?.id,
      location_contact_id: this.otherJobType?.oc?.data?.id,
    };
    let params: any = {};
    if (this.addSchedule) {
      data['interval_check'] = this.intervalCheck;

      this.schedulerForm.value.start_time = convertTime12to24(
        this.schedulerForm.value.start_time
      );
      this.schedulerForm.value.end_time = convertTime12to24(
        this.schedulerForm.value.end_time
      );
      this.schedulerForm.value.start_day =
        this.schedulerForm.value.start_day == ''
          ? ''
          : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
      this.schedulerForm.value.end_day =
        this.schedulerForm.value.end_day == null
          ? null
          : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
      let timezoneOffset = new Date();
      params.save_schedule = 1;
      data = {
        time_offset: timezoneOffset.getTimezoneOffset(),
        ...this.schedulerForm.value,
        ...data,
      };
    }
    this.jobService.createJob(data, params).then((res: any) => {
      if (res['status'] == 'success') {
        this.router.navigate(['/roster']);
      } else {
        this.addJobPressed = false;
        this.toasterService.setMessage({
          errorMessage: res['message'],
          successMessage: '',
        });
      }

      this.spinnerService.hide();
    });
  }

  getCompanyLicenses() {
    let params = { license_check: 1 };
    this.profileService
      .fetchCompanyDetails(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userData['checks']['license_reminder'] =
            response?.data?.license_reminder;
          this.siteLicenceReminderCheck = response?.data?.license_reminder;
          this.authService.setUserData(this.userData);
          this.companyLicenseList = response?.data?.license.map(
            (i: any) => i?.issuer_state
          );
        }
      });
  }
  showLicensePopup() {
    this.dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: {
        licenseState: this.otherJobType?.site?.data?.address?.state_code,
        companyLicense: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.companyLicenseList = value;
        this.dialogRef.close();
      }
    });
  }

  formatScheduleForm() {
    this.addSchedule = true;
    let passedDate = sessionStorage.getItem('selectedRosterDate') || new Date();

    let startDateTime = passedDate ? new Date(passedDate) : new Date();
    let currentTime = new Date();
    if (currentTime.getTime() > startDateTime.getTime()) {
      startDateTime = new Date();
    }
    startDateTime.setHours(
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds(),
      currentTime.getMilliseconds()
    );

    this.schedulerForm.controls['repeat_type'].setValue('once');
    this.schedulerForm.controls['end_day'].setValue(
      getFormattedDate(startDateTime)
    );
    this.schedulerForm.controls['end_day'].updateValueAndValidity();
    this.schedulerForm.controls['end_day'].clearAsyncValidators();
    this.schedulerForm.controls['start_day'].setValue(
      getFormattedDate(startDateTime)
    );
    this.schedulerForm.controls['start_time'].setValue(
      getFormattedTime(startDateTime)
    );

    sessionStorage.removeItem('selectedRosterDate');
    this.scheduleStepperActive = true;
  }

  updateInterval(interval: any) {
    this.intervalCheck = interval;
  }
  isScheduleFormValid() {
    this.schedulerForm.controls['end_day'].updateValueAndValidity();
    this.schedulerForm.controls['end_day'].clearAsyncValidators();
    return true;
  }
  calculateTimeDifference(
    startTime: string,
    endTime: string,
    startDate?: Date,
    endDate?: Date
  ) {
    var startDateTime = startDate ? new Date(startDate) : new Date();
    var endDateTime = endDate ? new Date(endDate) : new Date();

    var [startHours, startMinutes] = startTime.split(':').map(Number);
    var [endHours, endMinutes] = endTime.split(':').map(Number);

    startDateTime.setHours(startHours, startMinutes);
    endDateTime.setHours(endHours, endMinutes);

    var timeDifference = endDateTime.getTime() - startDateTime.getTime();

    // Convert milliseconds to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference;
  }
  getIntervalData() {
    let diffValue: any;
    const scheduleData: any = JSON.parse(
      JSON.stringify(this.schedulerForm.value)
    );
    if (scheduleData?.repeat_type === 'once') {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time),
        scheduleData.start_day,
        scheduleData.end_day
      );
    } else {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time)
      );
    }

    if (diffValue > 1) {
      this.intervalData.push({
        name: 'Welfare Checks Every 1 Hour',
        value: 60,
      });
    }
    if (diffValue > 2) {
      this.intervalData.push({
        name: 'Welfare Checks Every 2 Hours',
        value: 120,
      });
    }
  }
}
