import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserPromptService {
  constructor(private http: HttpClient) {}

  getUserPromptList(params: any) {
    return this.http.get('/api/user_prompt/', { params: params });
  }
  getHomeUserPromptList(params: any) {
    return this.http.get('/api/user_prompt/list_all_prompts/', {
      params: params,
    });
  }
  getUserPromptById(id: string, params?: any) {
    return this.http.get(`/api/user_prompt/${id}/`, { params: params });
  }
  createUserPrompt(body: any) {
    return this.http.post('/api/user_prompt/', body);
  }
  updateUserPrompt(id: string, body: any) {
    return this.http.put(`/api/user_prompt/${id}/`, body);
  }
  deleteUserPrompt(id: string) {
    return this.http.delete(`/api/user_prompt/${id}/`);
  }
}
