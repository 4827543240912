import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DynamicScriptLoadService } from '../../../../shared/services/dynamic-script-load.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { PagesService } from '../../pages.service';
import { SubscriptionService } from '../subscription.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { isLargeScreen } from '../../../../../global.variable';
declare var Square: any;

@Component({
  selector: 'gtapp-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrl: './saved-cards.component.scss',
})
export class SavedCardsComponent implements OnInit {
  @Output() selectCard = new EventEmitter();
  @Output() showAddCardForm = new EventEmitter();

  @Input() showOn: string = 'subscriptionComponent';
  savedCards: any = [];
  showSqForm: boolean = false;
  cardHolderName: any;
  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );
  dialogRef: any;
  newCard: any;
  largeView: Boolean = isLargeScreen;
  selectedCard: any;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private subscriptionService: SubscriptionService,
    private scriptLoader: DynamicScriptLoadService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private toasterService: ToasterService
  ) {}
  ngOnDestroy(): void {
    this.showSqForm = false;
  }
  deleteCard(card: any) {
    let dialogMsg = 'Are you sure you want to remove this payment card?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Card`,
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.subscriptionService
          .deleteSavedCard(card.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
              this.getSavedCards();
            } else {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  showAddCardFormFn() {
    if (
      this.savedCards?.length === 0 &&
      this.showOn !== 'subscriptionComponent'
    ) {
      this.spinnerService.show();
      setTimeout(() => {
        this.showAddCardForm.emit(true);
      }, 1000);
    } else {
      this.spinnerService.hide();
    }
  }
  addNewCard(template: TemplateRef<any>) {
    this.spinnerService.show();
    this.scriptLoader
      .load('square-script')
      .then((data) => {
        setTimeout(() => {
          this.addCard();
          this.dialogRef = this.dialogService.open(
            template,
            {
              data: {},
            },
            this.viewContainerRef
          );
        }, 1000);
      })
      .catch((error) => console.error(error));
  }

  ngOnInit(): void {
    this.getSavedCards();
  }

  getSavedCards() {
    let today = new Date();
    today.setDate(1);
    today.setHours(0, 0, 0, 0);

    this.subscriptionService.getSavedCards().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.savedCards = response?.data;

        this.showAddCardFormFn();
        this.savedCards.forEach((e: any) => {
          let cardDate = new Date(e.expiry_year + '-' + e.expiry_month + '-01');
          if (cardDate < today) {
            e['disbleCard'] = true;
          } else {
            e['disbleCard'] = false;
          }
        });
      }
    });
  }

  async addCard() {
    this.subscriptionService
      .getPaymentProviderConfigs()
      .subscribe(async (response: any) => {
        if (response['status'] == 'success') {
          var cardPreData = {};
          this.ngZone.run(() => {
            // Show spinner immediately
            this.showSqForm = true;
          });
          cardPreData = {
            postalCode: '123456',
          };
          const paymentProcessorConfig = response['data'];
          const payments = Square.payments(
            paymentProcessorConfig.app_id,
            paymentProcessorConfig.location_id
          );
          const card = await payments.card(cardPreData);
          setTimeout(() => {
            card.attach('#card-container');
            this.spinnerService.hide();
            this.newCard = card;
          }, 100);
        }
      });
  }
  tokenizeAddNewCard() {
    this.spinnerService.show();
    try {
      this.newCard
        ?.tokenize()
        .then((result: any) => {
          if (result.token) {
            this.subscriptionService
              .addNewPaymnetCard({
                source: result.token,
                card_holder_name: this.cardHolderName,
                idempotency_key: uuidv4(),
              })
              .subscribe(async (response: any) => {
                if (response?.status == 'success') {
                  this.spinnerService.hide();
                  this.toasterService.setMessage({
                    successMessage: response?.message,
                    errorMessage: '',
                  });
                  this.dialogRef.close();
                  this.getSavedCards();
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response?.message,
                  });
                  this.spinnerService.hide();
                }
              });
          } else {
            this.spinnerService.hide();
          }
        })
        .catch((error: any) => {
          // Handle tokenization error here
          console.error('Error tokenizing card:', error);
          this.spinnerService.hide();
        });
    } catch (error: any) {
      this.spinnerService.hide();
      console.error('Error tokenizing card:', error);
    }
  }
  changeCardStatus(cardData: any) {
    this.selectedCard = cardData;
    this.selectCard.emit(cardData);
  }
}
