import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {
  globalUserFeedbackStatusColor,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { PagesService } from '../pages.service';
import { betaFeedbackTable } from '../tableDetails';
declare let html2canvas: any;

@Component({
  selector: 'gtapp-app-user-feedback',
  templateUrl: './app-user-feedback.component.html',
  styleUrl: './app-user-feedback.component.scss',
})
export class AppUserFeedbackComponent implements OnInit {
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  selectedEvent: any;
  feedbacks: any = [];
  deskTopTableDetail = betaFeedbackTable.desktopTable;
  //mobileTableDetail = betaFeedbackTable.mobileTable;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  sortKey: any;
  globalFilter: any;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;
  scrollPosition: any;
  newComment: any;
  uploadedImage: any;
  fullImgSrc: any;
  dialogref: any;
  deleteReason: any;
  feedbackStatusColor: any = globalUserFeedbackStatusColor;
  mobileTableDetail = {
    ...betaFeedbackTable.mobileTable,
    groupDetails: (row: any) => {
      if (row?.status) {
        return {
          'background-color': `${this.feedbackStatusColor?.[row?.status]}`,
          'color': 'var(--color-basic-800)',
        };
      }
      return {};
    },
  };
  showDefaultFeedbacks: boolean = true;
  feedbackForm = new FormGroup({
    feedback: new FormControl('', [
      Validators.required,
      Validators.maxLength(340),
    ]),
  });
  fileData: any = [];
  feedbackScreenShot: any;
  feedbackDropDownList: any = [];
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  otherParams: any = {};
  constructor(
    private profileService: ProfileService,
    private spinnerService: LoadingSpinnerService,
    private pageService: PagesService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private appService: AppService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    this.getFeedbacks();
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getFeedbacks();
  }
  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.getFeedbacks();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  showAllFeedbacks() {
    this.showDefaultFeedbacks = !this.showDefaultFeedbacks;
    this.getFeedbacks();
  }
  onFeedBackLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.feedbackDropDownList?.length)
    ) {
      this.profileService
        .getFeedbacks({ search_str: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.feedbackDropDownList = res?.data;
          }
        });
    }
  }
  getFeedbacks() {
    this.spinnerService.show();
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.showDefaultFeedbacks) {
      params['show_default'] = 1;
    }

    this.profileService.getFeedbacks(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.feedbacks = response['data'];

        this.totalRows = response['total_size'];

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }

      this.spinnerService.hide();
    });
  }
  sortColumn(body: any) {
    this.sortKey = body?.sortList;

    this.getFeedbacks();
  }
  onFilterApply(event: any) {
    this.showSearchBox = true;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;

      this.globalFilter = '';
      this.getFeedbacks();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.getFeedbacks();

      this.showTypeAhead = false;
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;

    this.getFeedbacks();
  }
  getFeedBackDetails(id: number) {
    this.spinnerService.show();
    this.profileService.getFeedbackById(id).subscribe((response: any) => {
      this.formatData(response);

      this.spinnerService.hide();
    });
  }
  formatData(response: any) {
    this.newComment = null;
    this.selectedEvent = response;
    this.uploadedImage = this.selectedEvent?.file_url;
    setTimeout(() => {
      var messageBody: any = document.querySelector('.list-fixed-length');
      if (messageBody) {
        messageBody.scrollTop =
          messageBody?.scrollHeight - messageBody?.clientHeight;
      }
    }, 100);
  }
  onRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.getFeedBackDetails(event?.id);
  }
  hideDetails() {
    this.selectedEvent = null;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }
  addComment() {
    if (this.newComment) {
      this.spinnerService.show();
      this.profileService
        .updateFeedback(this.selectedEvent?.id, { comment: this.newComment })
        .subscribe((response: any) => {
          this.formatData(response?.data);

          this.spinnerService.hide();
        });
    }
  }
  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.dialogref = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openTemplate(template: TemplateRef<any>) {
    this.newComment = null;
    this.deleteReason = null;
    {
      this.dialogref = this.dialogService.open(
        template,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }
  }
  deleteFeedBack() {
    if (this.deleteReason) {
      this.spinnerService.show();
      this.profileService
        .updateFeedback(
          this.selectedEvent?.id,
          { comment: this.deleteReason },
          { delete: 1 }
        )
        .subscribe((response: any) => {
          this.selectedEvent = null;
          this.getFeedbacks();
          this.dialogref.close();
          this.spinnerService.hide();
        });
    }
  }
  openAddFeedbackForm(requestRef: TemplateRef<any>) {
    this.spinnerService.show();
    this.showHideDraggableFeedbackBtn(true);
    this.feedbackForm.controls['feedback'].setValue(null);

    const device = this.deviceService.getDeviceInfo();

    if (device?.device !== 'iPhone') {
      this.captureScreenShot();
    }
    setTimeout(() => {
      this.spinnerService.hide();
      this.dialogref = this.dialogService.open(
        requestRef,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }, 100);
    this.appService
      .getCurrentVersionInfo()
      .then((data: any) => sessionStorage.setItem('appVersion', data?.version));

    // this.spinnerService.hide();
    this.dialogref?.afterClosed()?.subscribe((value: any) => {
      if (value === 'close') {
        this.feedbackForm.controls['feedback'].setValue(null);
      }
    });
  }
  async captureScreenShot() {
    try {
      let section = document.querySelector('#mainContainer');
      const canvas = await html2canvas(section, {
        logging: false,
        useCORS: true,
      }).then((canvas: any) => {
        const arr = canvas.toDataURL().split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.spinnerService.hide();
        this.feedbackScreenShot = new File(
          [u8arr],
          `Upload_${(this.fileData.length = 1)}`,
          {
            type: 'image/jpeg',
          }
        );
      });
    } catch (error) {
      console.error('Error capturing screenshot:', error);
    }
  }
  addFeedback() {
    const feedbackValue: any = this.feedbackForm.get('feedback')?.value;
    this.spinnerService.show();
    let fileData: FormData = new FormData();
    fileData.append('file', this.feedbackScreenShot);
    fileData.append('feedback', feedbackValue);

    fileData.append(
      'app_version',
      String(sessionStorage.getItem('appVersion') || '')
    );
    this.appService
      .formDataApi(`feedback/submit_feedback`, fileData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.feedbackForm.controls['feedback'].setValue(null);
          this.dialogref.close();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.getFeedbacks();
        this.spinnerService.hide();
      });
  }
  showHideDraggableFeedbackBtn(value: any) {
    if (value === true) {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'hidden';
    } else {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'visible';
    }
  }
}
