<div
  [ngStyle]="{
    'padding-bottom': '80px'
  }"
>
  @if(!detailData?.is_active){
  <div class="alert alert-warning">
    DELETED CLIENT
    <button
      class="btn btn-info btn-sm"
      (click)="isAdmin ? archiveUnarchive() : ''"
    >
      Restore
    </button>
  </div>
  }
  <div class="row row-cols-1 row-cols-lg-3">
    <div class="col-12 col-lg-5">
      <dl class="cursor-pointer">
        <dt>
          Client Name
          <span
            (click)="
              (isAdmin || isDispatchUser) && detailData?.is_active
                ? openEditTemplate()
                : null
            "
          >
            <i
              class="fa-solid fa-pen-to-square"
              *ngIf="(isAdmin || isDispatchUser) && detailData?.is_active"
            ></i>
          </span>
          <span
            *ngIf="detailData?.is_active"
            class="ms-2 float-end"
            (click)="isAdmin ? archiveUnarchive() : ''"
          >
            <i class="fa-regular fa-trash-can fa-2x" *ngIf="isAdmin"></i>
          </span>
        </dt>

        <dd class="fw-bold">{{ detailData?.company_name }}</dd>
      </dl>
      <dl>
        <dt>Client Address</dt>

        <dd>
          {{ detailData?.address?.full_address }}
        </dd>
      </dl>
      <ng-container
        *ngIf="isAdmin || isDispatchUser || detailData?.information"
      >
        <dl
          class="cursor-pointer"
          (click)="
            (isAdmin || isDispatchUser) && detailData?.is_active
              ? openInformationTemplate(requestRef)
              : ''
          "
        >
          <dt>
            Client Information
            <span>
              <i
                class="fa-solid fa-pen-to-square"
                *ngIf="(isAdmin || isDispatchUser) && detailData?.is_active"
              ></i>
            </span>
          </dt>

          <dd class="gt-multi-line">{{ detailData?.information }}</dd>
        </dl>
      </ng-container>
      <ng-container *ngIf="isAdmin || isDispatchUser || clientFiles?.length">
        <dl class="cursor-pointer">
          <dt
            (click)="
              (isAdmin || isDispatchUser) && detailData?.is_active
                ? openFileUploadTemplate(uploadRef)
                : ''
            "
          >
            {{
              isAdmin || isDispatchUser
                ? "Client Files [" + clientFiles.length + " / 10 Max]"
                : "Client Files"
            }}
            <span>
              <i
                class="fa-solid fa-plus-circle"
                *ngIf="(isAdmin || isDispatchUser) && detailData?.is_active"
              ></i>
            </span>
          </dt>

          <dd>
            <table class="table table-borderless">
              <tbody>
                <tr *ngFor="let file of clientFiles" class="cursor-pointer">
                  <td>
                    <div class="d-flex">
                      <div class="flex-grow-1" (click)="onOpenFile(file)">
                        {{ file.description }}
                      </div>
                      <div
                        class="d-flex align-items-center me-3"
                        *ngIf="isAdmin || isDispatchUser"
                        (click)="deleteFile(file)"
                      >
                        <i class="fa-regular fa-trash-can fa-2x"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
      </ng-container>
    </div>
    <div class="col-12 col-lg-5">
      <dl class="cursor-pointer">
        <dt
          class="mb-2"
          (click)="
            isAdmin || (isDispatchUser && detailData?.is_active)
              ? addNewSite()
              : null
          "
        >
          Sites
          <span>
            <i
              class="fa-solid fa-plus-circle"
              *ngIf="(isAdmin || isDispatchUser) && detailData?.is_active"
            ></i>
          </span>
        </dt>

        <dd>
          @if(!siteData?.length){
          <div class="alert alert-warning">No Sites Available</div>
          }@else {
          <table class="table table-borderless">
            <tbody>
              <tr
                *ngFor="
                  let site of showSiteList ? fullSiteList : siteData;
                  let i = index
                "
                class="cursor-pointer"
                (click)="openSiteView(site)"
              >
                <td>
                  <div class="d-flex">
                    <!-- <div class="d-flex align-items-center me-3">
                      <i class="fa-solid fa-sitemap fa-2x"></i>
                    </div> -->
                    <div class="flex-grow-1">
                      {{ site?.company_name }}
                      <div class="small-font">
                        {{ site?.address?.full_address }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <button
              *ngIf="siteCount > 3"
              class="btn btn-secondary btn-sm"
              (click)="showAllSites()"
            >
              View All
            </button>
          </div>

          }
        </dd>
      </dl>
    </div>
    <div class="col-12 col-lg-2 d-none d-lg-block">
      <div class="row row-cols-1 gy-2">
        <div class="col">
          <button
            class="btn btn-secondary w-100 btn-md"
            (click)="openJobView()"
          >
            Jobs
          </button>
        </div>
        <div class="col">
          <button class="btn btn-secondary w-100 btn-md" (click)="openCPView()">
            Checkpoints
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row row-cols-1 row-cols-md-3 mt-4"
    *ngIf="isAdmin && detailData?.is_active"
  >
    <dl class="cursor-pointer">
      <dt (click)="openSchedulerTemplate()">
        Patrol Reports
        <span>
          <i class="fa-solid fa-pen-to-square"></i>
        </span>
      </dt>

      <dd>
        <div
          class="alert alert-warning"
          *ngIf="patrolReportSchedules?.length < 1"
        >
          No one is receiving patrol reports for checkpoint scans.
        </div>
        <div
          *ngIf="patrolReportSchedules.length > 0"
          class="small text-secondary"
        >
          <em>Reports for client updates will be sent to:</em>
        </div>

        <table class="table table-borderless">
          <tbody>
            <tr
              *ngFor="let schedule of patrolReportSchedules"
              class="cursor-pointer"
              (click)="openSchedulerTemplate(schedule)"
            >
              <td>
                <div class="d-flex">
                  <div class="d-flex align-items-center me-3">
                    <i class="fa-solid fa-calendar-days fa-2x"></i>
                  </div>
                  <div class="flex-grow-1">
                    <div class="fw-bold">{{ schedule?.name }}</div>

                    <div class="small-font">
                      {{ schedule?.schedule?.repeat_type | titlecase }} &#64;
                      {{ schedule?.mail_time }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </dd>
    </dl>
    <dl class="cursor-pointer">
      <dt (click)="openJobReportContactTemplate()">
        Job Reports
        <span>
          <i class="fa-solid fa-pen-to-square"></i>
        </span>
      </dt>

      <dd>
        <div class="alert alert-warning" *ngIf="userListData.length < 1">
          No one is receiving reports for completed jobs.
        </div>
        <div *ngIf="userListData.length > 0" class="small text-secondary">
          <em>Reports for completed jobs will be sent to:</em>
        </div>
        <table class="table table-borderless">
          <tbody>
            <tr
              *ngFor="let contact of userListData"
              class="cursor-pointer"
              (click)="openSiteContactTemplate(contact, detailData, 'jc')"
            >
              <td>
                <div class="d-flex">
                  <div class="d-flex align-items-center me-3">
                    <i class="fa-solid fa-address-book fa-2x"></i>
                  </div>
                  <div class="flex-grow-1">
                    {{ contact?.full_name }}
                    <div class="small-font">
                      {{ contact?.email }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <button
            *ngIf="clientData?.users?.length > 3"
            class="btn btn-secondary btn-sm"
            (click)="
              userListData?.length > 3
                ? (userListData = clientData?.users?.slice(0, 3))
                : (userListData = clientData?.users)
            "
          >
            {{ userListData?.length > 3 ? "View Less" : "View All" }}
          </button>
        </div>
      </dd>
    </dl>
    <dl class="cursor-pointer">
      <dt (click)="openClientContactTemplate()">
        Client Contacts
        <span>
          <i class="fa-solid fa-pen-to-square"></i>
        </span>
      </dt>

      <dd>
        <div *ngIf="!contactListTemp?.length" class="alert alert-warning">
          No Contacts Have Been Added To This Client Yet
        </div>
        <div *ngIf="contactListTemp.length > 0" class="small text-secondary">
          <em>Contacts assigned to the Client:</em>
        </div>
        <table class="table table-borderless">
          <tbody>
            <tr
              *ngFor="let contact of contactListTemp"
              class="cursor-pointer"
              (click)="openSiteContactTemplate(contact, detailData, 'lc')"
            >
              <td>
                <div class="d-flex">
                  <div class="d-flex align-items-center me-3">
                    <i class="fa-solid fa-address-book fa-2x"></i>
                  </div>
                  <div class="flex-grow-1">
                    {{ contact?.full_name }}
                    <div class="small-font">
                      {{ contact?.email }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </dd>
    </dl>
  </div>
</div>
<div class="bottom-section d-block d-lg-none" id="fixedBtn">
  <!-- online buttons (check conditions are different) -->
  <div>
    <ng-container>
      <div class="p-2">
        <!-- <div *ngIf="subscriberStats?.checkpoint_count != 0" class="mb-2">
          <div
            class="d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
            (click)="updateTask = 'scanCheckPoint'"
          >
            Scan Checkpoint
          </div>
        </div> -->
        <div class="d-flex justify-content-between">
          <div
            (click)="openJobView()"
            class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
          >
            <i class="fa-solid fa-briefcase fa-3x"></i>
          </div>
          <div
            (click)="openCPView()"
            class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
          >
            <i class="fa-solid fa-location-dot fa-3x"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- Templates used to show in Dialogue  -->

<!-- Client Information POPOVER / DIALOG -->
<ng-template #requestRef let-data let-requestRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Client Information
      <span class="float-end" (click)="requestRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Client Information"
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="requestRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          [disabled]="newInfromation === this.detailData?.information"
          (click)="requestRef.close(); updateClientInformation()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </div>
</ng-template>

<!-- Client file Upload DIALOG -->
<ng-template #uploadRef let-data let-uploadRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Upload File
      <span
        class="float-end"
        (click)="
          uploadRef.close();
          fileUploadError = null;
          fileDescription = null;
          uploadFile = null
        "
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="mt-3" *ngIf="fileUploadError">
      <div class="alert alert-danger">
        {{ fileUploadError }}
      </div>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-md w-100 btn-secondary"
        (click)="selectFile()"
      >
        {{ uploadFile ? "Change File" : "Upload File" }}
      </button>

      <input
        #fileInput
        id="fileInput"
        style="display: none; cursor: pointer"
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        (change)="handleFileInput($event)"
      />
    </div>

    <div class="form-floating mt-3">
      <input
        class="form-control"
        placeholder="File Description"
        fullWidth
        required
        maxlength="30"
        type="text"
        [(ngModel)]="fileDescription"
      /><label>File Description</label>
    </div>

    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="
            uploadRef.close();
            fileUploadError = null;
            fileDescription = null;
            uploadFile = null
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          [disabled]="!uploadFile || !fileDescription"
          (click)="onUploadFile(); uploadRef.close()"
        >
          Upload {{ uploadFile?.name }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
