import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formatDateTimeStamp,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { IncidentsService } from './incidents.service';

@Component({
  selector: 'gtapp-incidents',
  templateUrl: './incidents.component.html',
  styleUrl: './incidents.component.scss',
})
export class IncidentsComponent implements OnInit {
  incidentList: any;

  previous: number = 0;
  rows: number = window.innerWidth < 770 ? 5 : 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  sortKey: any;

  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };
  dateRange: any;
  incidentListTableDetails: any = [
    {
      header: 'Date',
      name: 'incident_time',
      datetimeObj: true,
      sortKey: 'incident_time',
    },

    {
      header: 'Incident Type',
      name: 'incident_type',
      sortKey: 'incident_type',
    },

    {
      header: 'Site',
      name: 'site_name',
      sortKey: 'site__company_name',
      searchKey: 'site_name',
    },

    {
      header: 'Client',
      name: 'company_name',
      sortKey: 'site__company__company_name',
      searchKey: 'site__company__company_name',
    },

    {
      header: 'Address',
      name: 'full_address',
      sortKey: 'full_address',
      searchKey: 'full_address',
    },

    {
      header: 'Reference No.',
      name: 'incident_key',
      searchKey: 'incident_key',
    },
    {
      header: 'Report Sent',
      name: 'submitted_at',
      datetimeObj: true,
      dateRangeKey: 'submitted_at',
      sortKey: 'submitted_at',
    },
  ];
  incidentCardDetails: any = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          if (row?.submitted_at) {
            return `Report Sent: ${String(
              formatDateTimeStamp(row?.submitted_at, 'd MMM y HH:mm', 'en_US')
            )}`;
          }
          return null;
        },
      },
      {
        name: 'incident_time',
        datetimeObj: true,
        cardRowStyle: { float: 'right' },
      },

      {
        name: 'incident_type',
      },

      {
        name: 'site_name',
      },
      {
        name: 'company_name',
        cardRowStyle: { 'font-size': 'small' },
      },
    ],
    groupDetails: (row: any) => {
      if (row?.submitted_at) {
        return {
          opacity: '0.6',
        };
      }

      return {};
    },
  };
  userData: any;
  isDispatchUser: boolean = false;
  isAdmin: any;

  globalFilter: any;

  searchParams: any;
  defaultIncidents: boolean = true;
  otherParams: any = {
    tableRowStyle: (rowData: any) => {
      if (rowData?.submitted_at) {
        return {
          opacity: '0.6',
        };
      }
      return {};
    },
  };
  incidentDropDownList: any = [];
  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  constructor(
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private incidentService: IncidentsService,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {}
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.userData = this.appService.getUserData();

    if (this.isAdmin || this.isDispatchUser) {
      // add created user to list view
      this.incidentListTableDetails.splice(-2, 0, {
        header: 'Guard',
        name: 'created_user',
        sortKey: 'created_user',
        searchKey: 'created_user',
      });
    }

    this.getIncidents();
  }
  showAllIncidents() {
    this.defaultIncidents = !this.defaultIncidents;
    this.getIncidents();
  }
  onIncidentLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.incidentDropDownList?.length)
    ) {
      this.incidentService
        .getIncidents({ name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.incidentDropDownList = res?.data;
          }
        });
    }
  }

  getIncidents(clientId?: any) {
    this.spinnerService.show();
    let params: any = {};
    let body: any = {};
    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['name'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.defaultIncidents) {
      params['default'] = 1;
    }

    if (this.searchParams) {
      body.ui_filters = this.searchParams;
    }
    this.incidentDropDownList = [];
    if (clientId) params['company_id'] = clientId;
    this.incidentService
      .getIncidents(body, params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalRows = response['total_size'];

          if (response['incident_count'] === 0) {
            this.addIncident();
          }
          this.totalPages = Math.ceil(this.totalRows / this.rows);
          this.otherParams.paginationData = {
            totalRows: this.totalRows,
            previousRows: this.previous,
            rows: this.rows,
            pageNum: this.pageNum,
            totalPages: this.totalPages,
          };
          this.incidentList = response['data'];
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }

  addIncident() {
    this.router.navigate(['/add-incident'], {
      relativeTo: this.route,
    });
  }
  onSelectIncident(event: any) {
    this.router.navigate(['/view-incident', { inKey: String(event?.id) }]);
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList;

    this.getIncidents();
  }

  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchParams = event?.searchParams || {};

    this.getIncidents();
  }
  onChangePagination(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    this.rows = data?.paginationData?.rows;

    this.getIncidents();

    this.otherParams.paginationData = { ...data?.paginationData };
  }
}
