<div class="p-1">
  <div class="row row-cols-sm-1 row-cols-lg-3">
    <!-- PROFILE PICTURE -->
    <div class="col-sm-3 col-lg-3 mb-3">
      <div class="col">
        <div
          class="row gy-2 row-cols-sm-1 row-cols-lg-1"
          [ngClass]="{
            'justify-content-center': !imageUploaded
          }"
        >
          <div
            (click)="openCamPopup()"
            [ngClass]="{
              'col col-7': imageUploaded,
              'col-6 text-center': !imageUploaded
            }"
            class="profile-picture-container"
          >
            <img
              class="mb-2"
              [ngClass]="
                largeView ? 'profile-picture' : 'profile-picture-mobile'
              "
              src="{{ userProfileUrl }}"
              alt="User-Profile-Image"
            />
          </div>
          <div *ngIf="imageUploaded" class="col col-5">
            <div class="row gy-2 row-cols-1">
              <div class="col">
                <button
                  class="btn btn-secondary w-100 btn-md"
                  (click)="openCamPopup()"
                >
                  Change Picture
                </button>
              </div>
              <div class="col">
                <button
                  class="btn btn-dark w-100 btn-md"
                  (click)="removeImage()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!imageUploaded">
          <div class="col text-center">
            <button class="btn-primary btn-sm btn" (click)="openCamPopup()">
              + Add Profile Picture
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- USER DETAILS -->
    <div class="col-sm-6 col-lg-6">
      <dl class="mb-1" class="row">
        <dl>
          <dt>Name</dt>
          <dd>{{ userDetails?.full_name }}</dd>
        </dl>
        <dl>
          <dt>Email</dt>
          <dd>{{ userDetails?.email }}</dd>
        </dl>
        <dl>
          <dt *ngIf="userDetails?.mobile_number">Telephone</dt>
          <dd *ngIf="userDetails?.mobile_number">
            {{ userDetails?.mobile_number }}
          </dd>
        </dl>

        <dl *ngIf="userDetails?.time_zone">
          <dt>Timezone</dt>
          <dd>
            {{ userDetails?.time_zone }} {{ userDetails?.timeZone?.gmtOffset }}
          </dd>
        </dl>

        <dt>
          Notification Sound
          <span class="text-primary">{{ muteNotification }} </span>
        </dt>
        <dd>
          <input
            style="width: 95%"
            type="range"
            [min]="0"
            placeholder="Notification Sound"
            [max]="100"
            [(ngModel)]="muteNotification"
            (mouseup)="changeNotificationVolume()"
            (touchend)="changeNotificationVolume()"
          />
        </dd>
      </dl>
    </div>

    <!-- BUTTONS -->
    <div class="col-sm-3 col-lg-3">
      <div class="row row-cols-2 row-cols-sm-1 gy-3">
        <div class="col">
          <button
            class="btn btn-primary w-100 btn-lg"
            (click)="editProfile(profileDialog)"
          >
            Update Details
          </button>
        </div>
        <ng-container *ngIf="isTrustedDevice">
          <div class="col">
            <button
              class="btn btn-primary w-100 btn-lg"
              (click)="openPasswordForm()"
            >
              Reset Password
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-primary w-100 btn-lg"
              (click)="checkEmailEnter($event)"
            >
              Update Email
            </button>
          </div>
        </ng-container>

        <div class="col">
          <!-- <button class="btn btn-dark w-100" (click)="toggleTheme()">
            Switch Theme
          </button> -->
        </div>

        <ng-container *ngIf="!userData?.checks?.fido_login && isTrustedDevice">
          <div class="col-12">
            <button
              class="btn btn-primary w-100 btn-lg"
              (click)="fidoRegister()"
            >
              Enable WebAuthn
            </button>
          </div>

          <div class="col-12">
            <a
              href="https://youtu.be/zJPNuORkvvk?t=61"
              target="_blank"
              rel="noopener"
            >
              <button class="btn btn-outline-primary w-100 btn-lg">
                What is WebAuthn?
              </button>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- USER LICENCES)-->
  <div class="my-2">
    <span>
      <dl class="mb-1">
        <dt class="fw-bold d-none d-md-inline-block">
          <span class="d-flex align-items-center">
            <span>Security Licences</span>
            <button
              class="ms-2 btn-primary btn-sm btn"
              (click)="addNewLicense()"
            >
              + Add Security Licence
            </button>
          </span>
        </dt>
      </dl>
      <button
        class="d-block d-md-none btn btn-primary w-100 btn-lg mb-3"
        (click)="addNewLicense()"
      >
        + Add Licence
      </button>
    </span>
    <div>
      <div
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxxl-6 g-3"
      >
        <div class="col" *ngFor="let licence of userLicenses">
          <div class="license-card">
            <div class="text-center">
              <span
                class="text-danger fw-bold"
                *ngIf="licence?.expiry_code === 2"
              >
                EXPIRED</span
              >
            </div>

            <div class="card-body pb-1 p-2">
              <div class="text-center"></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="me-3">
                  <dl class="mb-1">
                    <dt>Licence Number</dt>
                    <dd>
                      {{ licence.license_number }}
                    </dd>
                  </dl>

                  <dl class="mb-1">
                    <dt>Class</dt>
                    <dd>
                      {{ licence.license_class }}
                    </dd>
                  </dl>
                </div>
                <div class="text-center">
                  <div class="fw-bold text-primary">
                    <span class="display-6">{{ licence.issuer_state }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <dl class="mb-1">
                  <dt>Valid Till</dt>
                  <dd>{{ licence.valid_till }}</dd>
                </dl>

                <span
                  class="text-end"
                  (click)="deleteLicense(licence)"
                  title="Delete License"
                >
                  <i class="fa-regular fa-trash-can fa-2x"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <gtapp-card-list
        [mobileCardBackendData]="userLicenses"
        [mobileCardDetails]="licenseDetails"
        [mobileActionIcons]="cardActionIcons"
        (actionClick)="deleteLicense($event?.event)"
      ></gtapp-card-list> -->
    </div>
  </div>
</div>
<ng-template #profileDialog let-profileRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Edit Profile
      <span class="float-end" (click)="profileRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <form [formGroup]="profileForm">
        <div class="d-flex flex-column gap-2">
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="first_name"
              id="first_name"
              maxlength="150"
              required
            />
            <label for="first_name">First Name</label>
          </div>
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="last_name"
              id="last_name"
              maxlength="150"
              required
            />
            <label for="last_name">Last Name</label>
          </div>
          <div class="col-12 form-floating">
            <input
              class="form-control"
              type="tel"
              minlength="10"
              maxlength="10"
              (input)="mobileNumberCheck()"
              formControlName="mobile_number"
              id="mobile_number"
            />
            <label for="mobile_number">Telephone</label>
            <div
              *ngIf="
                profileForm.controls['mobile_number'].invalid &&
                profileForm.controls['mobile_number'].touched
              "
              class="text-danger"
            >
              Invalid mobile number
            </div>
          </div>
          <div class="col-12 form-floating">
            <select
              class="form-control"
              formControlName="time_zone"
              id="timeZone"
              (change)="onTimeZoneChange($event)"
            >
              <option
                *ngFor="let timeZone of stateTimeZones"
                [value]="timeZone.value"
              >
                {{ timeZone.value }} {{ timeZone.gmtOffset }}
              </option>
            </select>
            <label for="timeZone">Timezone</label>
          </div>
          <div class="row row-cols-2 mt-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-dark w-100 btn-lg"
                (click)="profileRef.close()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-primary w-100 btn-lg"
                (click)="UpdateProfileDetails(); profileRef.close()"
                [disabled]="!profileForm.valid"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
