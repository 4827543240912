import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { UsersService } from '../users.service';

@Component({
  selector: 'gtapp-licences',
  templateUrl: './licences.component.html',
  styleUrl: './licences.component.scss',
})
export class LicencesComponent implements OnInit {
  //highChartComponent = Highcharts;
  licenseTab = {
    tab1: 'Licenced',
    tab2: 'Un-Licenced',
  };
  unLicnesedGuardDetails = {
    individualDetails: [
      {
        name: 'full_name',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bolder',
        },
      },
      {
        name: 'email',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  unLicnesedGuardTableDetails = [
    {
      name: 'full_name',
      header: 'Guard',
    },
    {
      name: 'email',
      header: 'Email',
    },
  ];

  licencedGuardDetails = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          return this.licenceExpiryCheck(row);
        },
        cardRowStyle: {
          'color': 'var(--color-danger-500)',
          'display': 'block',
          'font-size': '1.2rem',
          'font-weight': '700',
          'text-align': 'center',
        },
      },
      {
        name: 'licence_number',
        cardRowStyle: { 'font-weight': 'bolder' },
      },
      {
        nestedValue: (row: any) => {
          return `${row?.licence_class} - ${row?.licence_state}`;
        },
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        nestedValue: (row: any) => {
          return `${row?.full_name} [${row?.email}]`;
        },
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  licenceDetailsDesktopTable = [
    {
      header: '',
      nestedValue: (row: any) => {
        return this.licenceExpiryCheck(row);
      },
    },
    {
      header: 'License',
      name: 'licence_class',
    },
    {
      header: 'Lic No.',
      name: 'licence_number',
    },

    {
      header: 'State',
      name: 'licence_state',
    },
    {
      header: 'Guard',
      name: 'full_name',
    },
  ];
  userDataList: any;
  expandedData: any;
  pieChartOptions: any = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Licences',
    },
    credits: { enabled: false },
    plotOptions: {
      showInLegend: true,
      series: {
        dataLabels: {
          format: '{point.name}: {point.y}',
        },
        point: {
          events: {
            click: (event: any) => {
              this.expandChartData(event?.point?.name);
            },
          },
        },
      },
    },
  };

  selectedItem: any;
  showChart: boolean = false;
  selectedTab: string = this.licenseTab.tab1;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialog: ModelDialogueService,
    private router: Router,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService
  ) {}

  ngOnInit(): void {
    this.getUserList();
  }
  licenceExpiryCheck(rowData: any) {
    let expiryDate = new Date(rowData?.licence_validity);
    let today = new Date();
    expiryDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (expiryDate === today) {
      return 'Expiring Today';
    } else if (expiryDate < today) {
      return 'Expired';
    } else {
      return `Expires on  ${formatDate(expiryDate, 'dd', 'en')}`;
    }
  }

  getUserList() {
    let params: any = { licenced_users: 1 };

    this.spinnerService.show();
    this.userService.getUserData(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userDataList = response?.data;
        // 0 = licenced users
        // 1 = not licenced users

        let pieData = [];
        for (const [k, v] of Object.entries(this.userDataList?.[0])) {
          let users = (v as any)?.users?.length;
          pieData.push({
            name: k,
            y: users,
          });
        }
        this.pieChartOptions.series = [
          {
            data: pieData,
          },
        ];
        setTimeout(() => {
          this.showChart = true;
        }, 100);
      }
      this.spinnerService.hide();
    });
  }
  expandChartData(header: string) {
    this.selectedItem = header;

    this.expandedData = this.userDataList?.[0]?.[header]?.users;
  }
  openGuardInfo(guardData: any) {
    if (guardData.id) {
      this.dialog.open(AddEditUserComponent, {
        data: {
          editUser: true,
          detailData: guardData,
        },
      });
    }
  }
  hasKeys(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }
  changeTab(tab: any) {
    this.selectedTab = tab;
  }
}
