<div>
  <!-- Iterate over each card -->
  <div [ngClass]="!largeView ? '' : 'd-flex flex-wrap'">
    <!-- Add New Card Button -->
    <div
      *ngFor="let savedCard of savedCards"
      class="mb-3"
      [ngClass]="[
        largeView ? 'me-3' : '',
        savedCard === selectedCard ? 'selected-card' : ''
      ]"
      style="flex: 0 1 300px"
    >
      <div class="gt-payment-card m-auto">
        <div
          class="card__front card__part"
          [ngClass]="savedCard?.is_primary ? '' : 'card__inactive'"
          (click)="changeCardStatus(savedCard)"
        >
          <div class="row mb-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span
                class="badge d-flex align-items-center"
                [ngClass]="savedCard?.is_primary ? 'bg-success' : ''"
              >
                <i
                  *ngIf="savedCard?.is_primary"
                  class="fa-solid fa-check me-2"
                ></i>
                {{ savedCard?.is_primary ? "PRIMARY" : "" }}
              </span>
              <span class="text-muted">{{ savedCard.brand }}</span>
            </div>
          </div>

          <div class="card_number">{{ savedCard.card_num }}</div>
          <div class="card__space-75">
            <span class="card__label">&nbsp;</span>
            <div class="card__info">{{ savedCard.holder_name }}</div>
          </div>
          <div class="card__space-25">
            <span class="card__label">Expiry</span>
            <div class="card__info">{{ savedCard.expiry }}</div>
          </div>
        </div>
        <div class="delete-icon" (click)="deleteCard(savedCard)">
          <button class="btn btn-sm btn-danger border-0 opacity-100">
            Delete
          </button>
        </div>
      </div>

      <div
        style="display: none !important"
        class="card h-100 cursor-pointer"
        [ngClass]="{
          'border-secondary':
            !savedCard?.is_primary && savedCard !== selectedCard,
          'border-primary': savedCard?.is_primary,
          'border-warning': savedCard === selectedCard && !savedCard?.is_primary
        }"
        (click)="changeCardStatus(savedCard)"
      >
        <div class="p-2">
          <!-- Primary Badge -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <span
              *ngIf="savedCard?.is_primary"
              class="badge bg-success d-flex align-items-center"
            >
              <i class="fa-solid fa-check me-2"></i> PRIMARY
            </span>
            <span class="text-muted">{{ savedCard.brand }}</span>
          </div>
          <!-- Card Number -->
          <h5 class="card-title">{{ savedCard.card_num }}</h5>
        </div>
        <!-- Expiry and Card Holder Name at the bottom -->
        <div class="card-footer">
          <div class="card-text text-end">
            <div>VALID THRU</div>
            <div>
              {{ savedCard.expiry }}
            </div>
          </div>
          <p class="mb-0"></p>
          <div>{{ savedCard.holder_name }}</div>

          <div class="text-end p-2">
            <span (click)="deleteCard(savedCard)">
              <i class="fa-regular fa-trash-can fa-2x"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card__btn-new m-auto pointer mb-5"
      (click)="addNewCard(addNewPaymentCardTemplateRef)"
    >
      + Add Card
    </div>
  </div>
</div>

<ng-template
  #addNewPaymentCardTemplateRef
  let-addNewPaymentCardTemplateRef="dialogRef"
>
  <div class="card dialog-box" data-bs-theme="light">
    <div class="card-header">
      Add Payment Card
      <button
        type="button"
        class="btn-close close-icon float-end"
        aria-label="Close"
        (click)="addNewPaymentCardTemplateRef.close()"
      ></button>
    </div>
    <ng-container *ngIf="showSqForm">
      <input
        class="form-control mt-3"
        type="text"
        [(ngModel)]="cardHolderName"
        id="name"
        placeholder="Name"
      />
      <div id="card-container" class="mt-2"></div>

      <button
        type="button"
        class="btn btn-primary btn-lg w-100 mb-2"
        [disabled]="!cardHolderName"
        (click)="tokenizeAddNewCard()"
      >
        Add Card
      </button>
      <div>
        Please note that we do not save your credit card details. These details
        are saved securely via our payment partner
        <a
          *ngIf="!isPWAApp"
          href="https://squareup.com/au/en/legal/general/ua"
          target="_"
          >SquareUp</a
        >
        <span *ngIf="isPWAApp">SquareUp</span>.
      </div>
    </ng-container>
  </div>
</ng-template>
