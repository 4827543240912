import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { DialogRef } from '../../../shared/components/modal-dialogue/model-dialogue.ref';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { MustMatch } from '../../../shared/validators/must-match-validator';
import { ProfileService } from '../../profile/profile.service';
import { PagesService } from '../pages.service';
import { UsersService } from '../users/users.service';
import { getFormattedDate } from '../../../../global.variable';

@Component({
  selector: 'gtapp-app-checks',
  templateUrl: './app-checks.component.html',
  styleUrl: './app-checks.component.scss',
})
export class AppChecksComponent implements OnInit {
  today = getFormattedDate(new Date());
  passwordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );
  verifyForm: any;
  nameForm: any;
  validDate = this.today;
  userPhoneForm: UntypedFormGroup = new UntypedFormGroup({
    last_4: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ]),
  });
  showErrorMsg: boolean = false;
  mobileNumberTries: number = 3;

  newlicense: any;
  showLicenseFields: any;
  profileForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    first_name: new UntypedFormControl('', Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    mobile_number: new UntypedFormControl(null),
    time_zone: new UntypedFormControl(''),
  });
  licenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl(null, [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
  });
  classData: any = [];
  showLicenseAddClass: boolean = false;
  stateData: any;
  licenseClassData: any;
  userData: any;
  isSuperUser: any;
  startForm: any;
  endForm: any;
  selectedItem: any;

  currentStatus: string = 'passwordSet';
  showPassword: boolean = false;

  addCancelButton: boolean = false;
  currentStep: number = 1;
  showClassDropdown: boolean = false;
  selectedTimeZone: any;
  stateTimeZones: any = [];
  licenseClassName: string = '';

  constructor(
    private appService: AppService,
    private dialogRef: DialogRef,
    private profileService: ProfileService,
    private userService: UsersService,
    private router: Router,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService
  ) {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.getStates();
    this.getCurrentStatus();
  }
  getCurrentStatus() {
    this.spinnerService.show();
    if (this.dataCheckService.hasPasswordSet() === true) {
      this.currentStatus = 'passwordSet';
      this.addCancelButton = true;
      this.spinnerService.hide();
    } else {
      this.currentStatus = 'profileDetails';
    }
  }
  onCloseDialogue() {
    this.dialogRef.close();
    window.localStorage.removeItem('resetPasswordPWA');
  }

  ngOnInit(): void {
    this.getTimeZoneDetails();
    if (
      this.userData?.profile?.first_name ||
      this.userData?.profile?.last_name
    ) {
      this.profileForm.controls['first_name'].setValue(
        this.userData.profile.first_name
      );
      this.profileForm.controls['last_name'].setValue(
        this.userData.profile.last_name
      );
    }
    this.profileForm.controls['time_zone'].setValue(
      this.userData.profile.time_zone
    );
  }

  addNewClass(newLicence: string) {
    if (this.licenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.licenseForm.value['issuer_state'],
        license_class_name: newLicence,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.showLicenseAddClass = false;
        this.licenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Please select the state to add new licence guard.',
      });
    }
  }

  getStates() {
    this.appService.getStates(1).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }

  selectState(event: any) {
    const state = this.stateData?.find(
      (state: any) => state?.code === event.target.value
    );

    this.userService.getClassByState(state?.id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
      }
    });
  }
  selectClass(classData: any) {
    this.licenseForm.controls['license_class'].setValue(classData);
  }

  onLicenceClassSearch(event: any) {
    this.showLicenseAddClass = false;
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
      if (!this.licenseClassData?.length) this.showLicenseAddClass = true;
    }
  }

  updateProfileData() {
    if (this.profileForm.valid) {
      if (!this.profileForm.value?.mobile_number) {
        this.profileForm.controls['mobile_number'].setValue(null);
      }

      this.profileForm.controls['email'].setValue(this.userData.profile.email);
      let requestData = { data: this.profileForm.value };
      requestData['data'] = this.profileForm.value;
      if (this.licenseForm.valid) {
        this.licenseForm.value.valid_till = formatDate(
          this.licenseForm.value.valid_till,
          'yyyy-MM-dd',
          'en'
        );
        requestData.data['license_data'] = this.licenseForm.value;
      }

      if (this.isSuperUser === true) {
        this.userService
          .editSuperAdmin(requestData, this.userData?.profile?.id)
          .subscribe((response: any) => {
            this.toasterService.setMessage({
              successMessage: 'Profile Updated',
              errorMessage: '',
            });

            if (this.dataCheckService.hasPasswordSet() === false) {
              this.currentStatus = 'passwordSet';
            } else {
              this.dialogRef.close('close');
            }

            this.userData.profile = response;
            this.appService.setUserData(this.userData);
            // this.showProfileDlg = false;
            // this.dialogRef.close('close');
          });
      } else {
        this.spinnerService.show();
        this.profileService
          .updateProfileDetails(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              if (this.dataCheckService.hasPasswordSet() === false) {
                this.currentStatus = 'passwordSet';
              } else {
                this.dialogRef.close('close');
              }

              this.userData.profile = response['data'];
              this.appService.setUserData(this.userData);
              this.spinnerService.hide();
              // this.showProfileDlg = false;
              // this.dialogRef.close('close');
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    }
  }
  changePassword() {
    this.userData = this.appService.getUserData();
    {
      if (this.isSuperUser === true) {
        this.profileService
          .changeAdminPassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.user_token = response.user_token;
              userData.checks.password_set = true;
              localStorage.removeItem('linkfrom');
              this.appService.setUserData(userData);
              this.dialogRef.close('close');
            }
          });
      } else {
        this.profileService
          .changePassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              let userData = this.appService.getUserData();
              userData.user_token = response.user_token;
              userData.checks.password_set = true;
              localStorage.removeItem('linkfrom');
              localStorage.removeItem('resetPasswordPWA');
              this.appService.setUserData(userData);
              this.dialogRef.close('close');
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    }
  }
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  classSelected(data: any) {
    this.licenseForm.controls['license_class'].setValue(data);
    this.showClassDropdown = false;
  }
  getTimeZoneDetails() {
    this.stateTimeZones = [];
    [
      'Australia/Darwin',
      'Australia/Sydney',
      'Australia/Brisbane',
      'Australia/Adelaide',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
    ].forEach((timeZone: any) => {
      this.stateTimeZones.push(this.getTimezoneInfo(timeZone));
    });
  }
  getTimezoneInfo(timeZone: string): {
    timezone: string;
    localTime: string;
    gmtOffset: string;
  } {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    let offsetString: any = formatter
      .formatToParts(now)
      .find((part) => part.type === 'timeZoneName')?.value;
    const utcIndex = offsetString.indexOf('GMT');
    offsetString = offsetString.slice(utcIndex + 3);
    // Check if minutes part is zero
    if (offsetString.length <= 3) {
      offsetString += ':00';
    }

    const info: any = {
      localTime: now.toLocaleTimeString('en-AU', options),
      gmtOffset: `(UTC ${offsetString})`,
      value: timeZone,
    };
    return info;
  }
  onTimeZoneChange(event: any) {
    this.selectedTimeZone = event?.target.value;
  }
}
