<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="flex-sm-grow-1 mb-2 mb-sm-0">
    <gtapp-auto-complete
      [data]="siteDropDownList"
      placeHolder="Search Sites"
      searchKeyword="company_name"
      [itemTemplate]="userTemplate"
      (inputChanged)="onSiteLookup($event)"
      (selected)="openSiteView($event)"
      (submitted)="globalFilter = $event; sitePaginationData = {}; getSites()"
    >
    </gtapp-auto-complete>

    <ng-template #userTemplate let-item>
      <div>{{ item?.company_name }}</div>
    </ng-template>
  </div>
  <div class="ms-sm-2">
    <button class="btn btn-primary btn-lg w-100" (click)="openAddSiteForm()">
      + Add Site
    </button>
  </div>
</div>

@if (!largeView) {
<div>
  <gtapp-card-list
    [mobileCardBackendData]="sitePaginationData[pageNumSite]"
    [mobileCardDetails]="siteCardViewDetail"
    [otherParams]="otherParams"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openSiteView($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-card-list>
</div>
}@else {
<!-- DESKTOP VIEW -->
<div>
  <gtapp-gt-table
    [desktopTableBackendData]="sitePaginationData[pageNumSite]"
    [desktopTableDetails]="siteTableDetail"
    [tableStyle]="tableStyle"
    [otherParams]="otherParams"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openSiteView($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-gt-table>
</div>
}
