import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isLargeScreen, isLargeScreenFun } from '../../../../global.variable';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { AddEditUserComponent } from '../users/add-edit-user/add-edit-user.component';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-user-group',
  templateUrl: './user-group.component.html',
  styleUrl: './user-group.component.scss',
})
export class UserGroupComponent implements OnInit {
  userGroupData: any = [];
  selectedUserGroup: any = {};
  userGroupCardDetails = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        name: 'description',
        cardRowStyle: {
          'margin-top': '0.5rem',
        },
      },
    ],
  };
  dialogRef: any;

  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  constructor(
    private userService: UsersService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    this.getUserGroups();
  }

  getUserGroups() {
    this.spinnerService.show();
    this.userService.getUserGroups({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userGroupData = (response?.data || [])?.filter(
          (grp: any) => grp?.group_id !== 4
        );
      }
      this.spinnerService.hide();
    });
  }

  rowClick(event: any) {
    this.getUserGroupById(event?.id);
  }

  formatData(data: any) {}

  getUserGroupById(id: Number) {
    this.spinnerService.show();
    this.userService.getUserGroupById(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.selectedUserGroup = response?.data;
      } else {
        this.toasterService.setMessage({
          errorMessage: response['message'],
          successMessage: '',
        });
      }
      this.spinnerService.hide();
    });
  }
  openUserDialogBox(user: any) {
    this.dialogRef = this.dialogService.open(AddEditUserComponent, {
      data: {
        editUser: true,
        detailData: user,
      },
    });
  }
}
