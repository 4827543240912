<div class="p-1">
  <input
    #fileInput
    id="fileInput"
    style="display: none; cursor: pointer"
    type="file"
    accept="image/*"
    (change)="handleFileInput($event.target)"
  />
  <div>
    <div class="row">
      <!-- Image and buttons section -->
      <div class="col-12 col-lg-3">
        <div
          class="row gy-2 row-cols-lg-1"
          [ngClass]="{
            'row-cols-sm-2': imageUploaded,
            'row-cols-sm-1': !imageUploaded,
            'justify-content-center': !imageUploaded
          }"
        >
          <div
            [ngClass]="{
              'col col-7': imageUploaded,
              'col-6 text-center': !imageUploaded
            }"
            class="subscriber-logo-container"
          >
            <img
              [ngClass]="
                largeView ? 'subscriber-logo' : 'subscriber-logo-mobile'
              "
              src="{{ subscriberLogoUrl }}"
              alt="User-Profile-Image"
            />
          </div>
          <div *ngIf="imageUploaded" class="col col-5">
            <div class="row gy-2 row-cols-1">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-secondary w-100 btn-lg"
                  (click)="openCamPopup()"
                >
                  Change Picture
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-dark w-100 btn-lg"
                  (click)="removeImage()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="!companyDetails?.logo_url && isAdmin"
          class="row cursor-pointer"
        >
          <div class="col text-center">
            <button
              type="button"
              class="btn-primary btn-sm btn mt-2"
              (click)="openCamPopup()"
            >
              + Add Logo
            </button>
          </div>
        </div>
      </div>

      <!-- Company details and update button section -->
      <div class="col-12 col-lg-6">
        <div class="row row-cols-1 mt-1">
          <div class="col">
            <dt>Company Name</dt>
            <dd
              (click)="isAdmin ? openTemplate(companyDialog) : ''"
              class="badge-value fw-bold"
            >
              {{ companyDetails?.name }}
            </dd>
          </div>
          <div class="col">
            <dt>Address</dt>
            <dd class="badge-value">
              {{ companyDetails?.address?.full_address }}
            </dd>
          </div>
          <div class="col" *ngIf="companyDetails?.phone">
            <dt>Phone</dt>
            <dd class="badge-value">{{ companyDetails?.phone }}</dd>
          </div>
          <div class="col">
            <dt>Email</dt>
            <dd class="badge-value">{{ companyDetails?.email }}</dd>
          </div>
        </div>
      </div>

      <!-- Update details button section -->
      <div class="col-12 col-lg-3">
        <div class="mt-3">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="openTemplate(companyDialog)"
          >
            Update Details
          </button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <dl>
        <dt class="fw-bold d-none d-md-inline-block">
          <span class="d-flex align-items-center">
            <span>Company Licence</span>
            <button
              type="button"
              class="ms-2 btn-primary btn-sm btn"
              (click)="addNewLicense()"
            >
              + Add Company Master Licence
            </button>
          </span>
        </dt>
      </dl>
      <button
        type="button"
        class="d-block d-md-none btn btn-primary w-100 btn-lg"
        (click)="addNewLicense()"
      >
        + Add Company Master Licence
      </button>

      <gtapp-card-list
        [mobileCardBackendData]="companyLicenses"
        [mobileCardDetails]="licenseDetails"
        [mobileActionIcons]="cardActionIcons"
        (actionClick)="deleteLicense($event?.event)"
      ></gtapp-card-list>
    </div>
    <ng-container *ngIf="isAdmin || isDispatchUser">
      <div class="mt-5 fw-bold">Event Logs</div>
      <gtapp-user-update-log [eventType]="'subscriber'">
      </gtapp-user-update-log>
    </ng-container>
  </div>
</div>

<ng-template #companyDialog let-cpRef="dialogRef">
  <div class="card dialog-box p-3">
    <div class="card-header">
      Company Details
      <span
        class="float-end"
        (click)="companyDialogueStep = 1; cpRef.close('close')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <form [formGroup]="companyForm" gtappFormEnterAction>
      <div class="d-flex flex-column gap-2">
        <ng-container *ngIf="companyDialogueStep === 1">
          <div *ngIf="companyDetails?.name" class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="name"
              placeholder="Company Name"
            />
            <label for="name">Company Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              formControlName="email"
              placeholder="Email"
            />
            <label for="email">Email</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              minlength="10"
              maxlength="10"
              formControlName="phone"
              placeholder="Telephone"
            />
            <label for="phone">Telephone</label>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-secondary w-100"
                (click)="companyDialogueStep = 1; cpRef.close('close')"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                (click)="companyDialogueStep = 2"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="companyDialogueStep === 2">
          <div class="mt-2">
            <gtapp-auto-complete
              [data]="addrLookupData"
              placeHolder="Address Lookup"
              searchKeyword="full_address"
              [initialValue]="companyForm.value.addressLookup"
              [itemTemplate]="addressTemplate"
              [addInitialKey]="true"
              (inputChanged)="onAddressSearch($event)"
              (selected)="addressSelected($event)"
            >
            </gtapp-auto-complete>
            <ng-template #addressTemplate let-item>
              @if (item?.key === 0) {
              <div>Enter address manually</div>
              } @else {
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
              }
            </ng-template>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              formControlName="address1"
              maxlength="30"
              placeholder="Street Number"
            />
            <label for="address1">Street Number</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="address2"
              maxlength="100"
              autocomplete="off"
              placeholder="Street"
            />
            <label for="address2">Street Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="address3"
              maxlength="100"
              autocomplete="off"
              placeholder="Unit"
            />
            <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="city_name"
              maxlength="100"
              autocomplete="off"
              placeholder="City"
              (input)="forceUppercaseConditionally($event)"
            />
            <label for="city_name">Suburb</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="number"
              formControlName="postcode"
              autocomplete="off"
              placeholder="Post Code"
              maxlength="30"
            />
            <label for="postcode">Post Code</label>
          </div>
          <div>
            <select class="form-select" formControlName="state_code">
              <option *ngFor="let state of stateData" [value]="state.code">
                {{ state.code }}
              </option>
            </select>
          </div>
          <div class="row row-cols-2 gy-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-dark w-100"
                (click)="companyDialogueStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                id="submitbtn"
                [disabled]="!companyForm.valid"
                (click)="updateData(); cpRef.close(); companyDialogueStep = 1"
              >
                Submit
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-template>
